<template>
 <panel :title="title" noButton="true" v-has-perms="rolePerm">
  <div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.typology"
          :external-errors="errors['typology']"
          :label-form="'Email Type' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          label-search="name"
          :customize-option="true"
          :value="form.typology ? { value: form.typology, name: form.typology_name } : null"
          :options="emailTypologyOptions"
          @select="selectEmailTypology"
          @clear="clearEmailTypology"
        />
        <form-thux-horizontal-input
          :validator="$v.form.email"
          :external-errors="errors['email']"
          :label-form="'Email' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          type="email"
          :value="form.email"
          :disabled="!formEditable"
          @change="$set(form, 'email', $event)"
        />
        <form-thux-horizontal-text-area
          :validator="$v.form.note"
          :label-form="'Note' | translate"
          class-form="col-12 my-2"
          labelColsSm="4"
          labelColsLg="3"
          rows="3"
          max-rows="6"
          :value="form.note"
          :disabled="!formEditable"
          @change="$set(form, 'note', $event)"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.form.is_primary"
          :external-errors="errors['is_primary']"
          :label-form="'Primary Email' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.is_primary"
          :disabled="!formEditable"
          @change="$set(form, 'is_primary', $event)"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.form.is_for_training"
          :external-errors="errors['is_for_training']"
          :label-form="'Training Email' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.is_for_training"
          :disabled="!formEditable"
          @change="$set(form, 'is_for_training', $event)"
        />
      </b-form-row>
    </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_EMAIL_TYPES } from './store'
import PopulateSelectMixin from '@/components/PopulateSelectMixin'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import EmailTypologyMixin from './mixins/EmailTypologyMixin'

export default {
  name: 'OrganizationEmailEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, PopulateSelectMixin, EmailTypologyMixin],
  data () {
    return {
      pageName: 'Organization email',
      rolePerm: 'organization_organizationemail_retrieve',
      editRolePerm: 'organization_organizationemail_update',
      orgId: undefined,
      emailId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.DETAIL.ACTIONS,
      getEmailList: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.ACTIONS.getList
    }),
    initFields () {
      this.$set(this, 'emailId', this.id)
      this.$set(this, 'orgId', this.editParams.orgId)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.emailId) {
        formData.organization = this.orgId
      }
      return formData
    },
    onSubmit () {
      const formData = this.getFormData()
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          setTimeout(() => {
            if (!this.id) {
              this.closeForm()
            } else {
              this.makeFormReadonly()
              this.getEmailList()
            }
          }, 500)
        }
      )
    }
  },
  created () { },
  validations () {
    const vals = {
      form: {
        typology: {
          required
        },
        email: {
          required,
          email
        },
        is_primary: {},
        is_for_training: {},
        note: {}
      }
    }
    return vals
  }
}
</script>
