<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PHONE_EMAIL_TYPES } from '../store'

export default {
  name: 'PhoneTypologyMixin',
  created () {
    if (!this.phoneTypes || (this.phoneTypes && this.phoneTypes.length === 0)) {
      this.getPhonetypes()
    }
  },
  computed: {
    ...mapGetters({
      phoneTypes: PHONE_EMAIL_TYPES.GENERIC.organization.organizationphone.LIST.GETTERS.phoneTypes
    }),
    emailTypologyOptions () {
      return this.prepareListForSelect(this.phoneTypes, 'id', ['name'])
    }
  },
  methods: {
    ...mapActions({
      getPhonetypes: PHONE_EMAIL_TYPES.GENERIC.organization.organizationphone.LIST.ACTIONS.getPhonetypes
    }),
    selectEmailTypology (item) {
      this.$set(this.form, 'typology', item.value)
      this.$set(this.form, 'typology_name', item.name)
    },
    clearEmailTypology () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'typology_name', null)
    }
  }
}
</script>
