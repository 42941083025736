<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import OrganizationAddressTable from './OrganizationAddressTable'
import OrganizationAddressEdit from './OrganizationAddressEdit.vue'

export default {
  name: 'OrganizationAddressList',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationAddressTable,
    'component-edit': OrganizationAddressEdit
  },
  data () {
    return {
      title: this.$t('Addresses'),
      rolePerm: ['organization_organizationaddress_list'],
      actionEnablePermission: ['organization_organizationaddress_enable'],
      actionDisablePermission: ['organization_organizationaddress_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: []
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationaddress_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__organization__id: this.orgId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'orgId', this.orgId)
      if (id) {
        this.$set(this.editParams, 'addressId', id)
      } else {
        this.$set(this.editParams, 'addressId', undefined)
      }
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
