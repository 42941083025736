<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showEditButton="showEditButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-edit-form="$emit('open-edit-form', $event)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_IPA_ADDRESS_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'OrganizationIpaAddressTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'ipa_code',
          label: this.$t('IPA code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ipa_code'
        },
        {
          key: 'cig_code',
          label: this.$t('CIG code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'cig_code'
        },
        {
          key: 'cup_code',
          label: this.$t('CUP code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'cup_code'
        },
        {
          key: 'recipient',
          label: this.$t('Recipient'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'recipient'
        },
        {
          key: 'address_scopes',
          label: this.$t('Address scope'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'scopes'
        },
        {
          key: 'address_type',
          label: this.$t('Address Type'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'address_type_name'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'number',
          label: this.$t('N.'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'number'
        },
        {
          key: 'zip_code',
          label: this.$t('Zip code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'zip_code'
        },
        {
          key: 'locality',
          label: this.$t('Locality'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'locality'
        },
        {
          key: 'province',
          label: this.$t('Prov.'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'province'
        },
        {
          key: 'country__name',
          label: this.$t('Country'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'country_name'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: [
        'status', 'address_scopes', 'org_name', 'address_type',
        'name', 'number', 'zip_code', 'locality', 'province', 'country__name'
      ]
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.GETTERS.selectAll,
      selectedList: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showEditButton () {
      return hasPerm('organization_ipaaddress_update')
    }
  },
  methods: {
    ...mapMutations({
      setList: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setList,
      setSelectedList:
        ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_IPA_ADDRESS_TYPES.GENERIC.organization.organizationipaaddress.LIST.ACTIONS
    })
  }
}
</script>
