<template>
  <div class="panel-detail panel-fixed panel-with-actions">
    <div class="panel-header">
      <h1 v-if="detail && detail.name" class="page-header org-detail-logo">
        <img class="mr-3" v-if='oldLogo' :src='oldLogo'/>
        {{ detail.name }}
        <!-- TODO Check -->
        <div v-if="$route.params.id && (routeDocumentNameList || detail) && (routeOrganizationInspectionList || detail)" class="page-header-actions">
          <b-btn
            v-if="showGoToDocumentsButton"
            variant="outline-primary"
            v-has-perms="showOrgDocsRolePerm"
            :title="'Show documents filtered by organization' | translate"
            :to="{
              name: routeDocumentNameList,
              params: { orgId: $route.params.id, orgName: detail.name }
            }"
          >
            <i class="far fa-file-alt"></i>
          </b-btn>
          <b-btn
            v-if="showDownloadDocumentsButton"
            variant="outline-primary"
            v-has-perms="downloadOrgDocsRolePerm"
            :title="'Download organization documents' | translate"
            @click="openDownloadDocumentsModal()"
          >
            <i class="fas fa-cloud-download-alt"></i>
          </b-btn>
          <b-btn
            v-if="showSummaryButton"
            variant="outline-primary"
            v-has-perms="summaryExcelRolePerm"
            :title="'Summary Excel' | translate"
            @click="getSummaryDocument()"
          >
            <i class="fas fa-file-excel"></i>
          </b-btn>
          <b-btn
            v-if="showCompanyRegistryButton"
            variant="outline-primary"
            v-has-perms="downloadCompanyRegistryRolePerm"
            :title="'Download Company Registry' | translate"
            @click="openCompanyRegistryModal()"
          >
            <i class="fas fa-file-download"></i>
          </b-btn>
        <b-btn
          v-if="showDownloadNominationsButton"
          variant="outline-primary"
          v-has-perms="downloadNominationsRolePerm"
          :title="'Download Nominations' | translate"
          @click="openDownloadNominationsModal()"
        >
          <i class="fas fa-award"></i>
        </b-btn>
        <b-btn
          v-if="showOrganizationInspectionsButton"
          variant="outline-primary"
          v-has-perms="inpesctionsRolePerm"
          :title="'Organization Inspections' | translate"
          :to="{
              name: routeOrganizationInspectionList,
              params: { orgId: $route.params.id, orgName: detail.name }
            }"
        >
          <i class="fas fa-wrench"></i>
        </b-btn>
        </div>
      </h1>
      <ol class="breadcrumb pull-right">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item" v-if="orgType !== ORGANIZATION_OWNER_TYPE_CODE">
          <router-link :to="{ name: routeNameList }">
            {{ title | translate }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          {{ "Organization" | translate }}
        </li>
      </ol>
    </div>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <div v-if="detail && detail.name">
      <panel v-if="form" :title="$t('Organization Data')" noButton="true">
        <organization-form
          :id="id"
          :logo-placeholder="logoPlaceholder"
          :router-name-list="routeNameList"
          :form-initial="form"
          :errors="errors"
          :org-type="orgType"
          :org-type-list="orgTypeChoices"
          :org-group-list="groups"
          @search-group="onSearchGroup"
          :org-form-list="organizationform"
          :org-inv-type-list="typologyChoices"
          :org-employee-cat-list="employeeCategoryChoices"
          :operation="'update'"
          :edit-role-perm="editRolePerm"
          @submit="onSubmit"
        />
      </panel>
      <duplicated-organization-modal
        v-if="showDuplicatedForm"
        v-on:close-modal="closeModal"
        :org-type="orgType"
        :orgId="id"
        v-on:close-modal-and-go-to-detail="goToDetailPage"
        :orgFilters="orgFilters"
      ></duplicated-organization-modal>
      <organization-row-edit v-if="showOrgRowEdit" :org-type="orgType" :id="detail.row" :orgId="id" :key="componentKeys[0]"></organization-row-edit>
      <inail-code-list :orgId="id" :is-detail=true :key="componentKeys[1]"></inail-code-list>
      <organization-ipa-address-list v-if="form && form.typology === 'PA'" :orgId="id" :is-detail=true :key="componentKeys[8]"></organization-ipa-address-list>
      <organization-address-list :orgId="id" :is-detail=true :key="componentKeys[2]"></organization-address-list>
      <organization-email-list :orgId="id" :is-detail=true :key="componentKeys[3]"></organization-email-list>
      <organization-phone-list :orgId="id" :is-detail=true :key="componentKeys[4]"></organization-phone-list>
      <bank-list v-if="orgType==='own'" :is-detail=true :org-type="orgType" :orgId="id" :key="componentKeys[7]"></bank-list>
      <!-- <organization-web-site-list :orgId="id" :is-detail=true :key="componentKeys[5]"></organization-web-site-list> -->
      <component-contact-list :orgId="id" :is-detail=true :key="componentKeys[6]"></component-contact-list>
    </div>
    <download-documents-modal
      v-if="showDownloadDocumentsModal"
      :orgId="id"
      :orgName="detail.name"
      @close-modal="closeDownloadDocumentsModal()"
    />
    <company-registry-modal
      v-if="showCompanyRegistryModal"
      :orgId="id"
      @close-modal="closeCompanyRegistryModal()"
      @download-company-registry="downloadRegistry($event)"
    />
    <download-nominations-modal
      v-if="showDownloadNominationsModal"
      :orgId="id"
      :orgName="detail.name"
      @close-modal="closeDownloadNominationsModal()"
    />
  </div>
</template>

<script>
import PopulateSelectMixin from '@/components/PopulateSelectMixin'
import InailCodeList from '../../../inail-code/InailCodeList'
import OrganizationRowEdit from '../../../organization/organization-row/OrganizationRowEdit'
import OrganizationAddressList from '../../../organization/organization-address/OrganizationAddressList'
import OrganizationEmailList from '../../../organization/organization-email/OrganizationEmailList'
import OrganizationPhoneList from '../../../organization/organization-phone/OrganizationPhoneList'
import OrganizationIpaAddressList from '../../../organization/organization-ipa-address/OrganizationIpaAddressList'
import BankList from '../../../../bank/bank/BankList'
import DownloadDocumentsModal from '../organization-modal/DownloadDocumentsModal.vue'
// import OrganizationWebSiteList from '../../../organization/organization-website/OrganizationWebSiteList'
import OrganizationForm from '../OrganizationForm'
import DuplicatedOrganizationModal from '../organization-modal/DuplicatedOrganizationModal'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../../organization/organization/customer-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../../organization/organization/supplier-organization/router'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_OWNER_TYPE_CODE } from '../../../../../const'
import { vue } from '../../../../../main'
import { TYPES as ORG_TYPES } from '../../../organization/store'
import { mapActions } from 'vuex'
import CompanyRegistryModal from '../organization-modal/CompanyRegistryModal'
import DownloadNominationsModal from '../organization-modal/DownloadNominationsModal'

export default {
  name: 'OrganizationMixin',
  mixins: [PopulateSelectMixin],
  props: ['id'],
  watch: {
    id: function () {
      this.init()
      this.componentKeys = this.componentKeys.map(function (item) {
        return item + 1
      })
    }
  },
  components: {
    DuplicatedOrganizationModal,
    InailCodeList,
    OrganizationRowEdit,
    OrganizationAddressList,
    OrganizationEmailList,
    OrganizationPhoneList,
    // OrganizationWebSiteList,
    OrganizationIpaAddressList,
    OrganizationForm,
    BankList,
    DownloadDocumentsModal,
    CompanyRegistryModal,
    DownloadNominationsModal
  },
  data () {
    return {
      title: '',
      routeNameList: '',
      routeDocumentNameList: null,
      routeOrganizationInspectionList: null,
      orgType: null,
      form: undefined,
      formEditable: true,
      showOrgRowEdit: false,
      oldLogo: undefined,
      logoPlaceholder: undefined,
      showDuplicatedForm: false,
      orgFilters: undefined,
      componentKeys: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      showDownloadDocumentsButton: false,
      showDownloadDocumentsModal: false,
      showCompanyRegistryModal: false,
      showCompanyRegistryButton: false,
      showSummaryButton: true,
      showGoToDocumentsButton: true,
      showDownloadNominationsButton: true,
      showOrganizationInspectionsButton: true,
      showDownloadNominationsModal: false,
      ORGANIZATION_OWNER_TYPE_CODE,
      // Permissions
      showOrgDocsRolePerm: ['document_uploaddocument_organization_documents_list'],
      downloadOrgDocsRolePerm: ['document_uploaddocument_generate_documents_zip_job'],
      summaryExcelRolePerm: ['organization_organization_download_summary'],
      downloadCompanyRegistryRolePerm: ['organization_organization_download_company_registry'],
      downloadNominationsRolePerm: ['organization_organizationcontactsecurityrole_generate_download_nomination_job'],
      inpesctionsRolePerm: ['organization_organizationinspection_list'],
      editRolePerm: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.retrieve({ id: this.id }).then(() => {
        const orgData = Object.assign({}, this.detail)
        if (orgData.logo) {
          this.oldLogo = orgData.logo
          this.logoPlaceholder = orgData.logo_filename
        }
        orgData.logo = undefined
        this.$set(this, 'form', orgData)
        this.showOrgRowEdit = true
      })
      this.getListOptions()
    },
    ...mapActions({
      downloadSummary: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.download,
      downloadCompanyRegistry: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.downloadCompanyRegistry
    }),
    getListOptions () {
      this.getOrganizationform()
      this.setGroups({})
      this.getTypologychoices()
      this.getOrgtypechoices()
      this.getEmployeecategorychoices()
    },
    // organization groups
    onSearchGroup (filters) {
      if (filters) {
        this.getGroups(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setGroups({})
      }
    },
    /// //////////////////////
    onSubmit (form) {
      const formData = Object.assign({}, form.data)
      if (!(formData.logo instanceof File)) {
        formData.logo = undefined
      }
      if (!formData.row) {
        delete formData.row
      }
      this.update(formData).then(() => {
        const orgData = Object.assign({}, this.detail)
        if (orgData.logo) {
          this.oldLogo = orgData.logo
          this.logoPlaceholder = orgData.logo_filename
          delete orgData.logo
          delete this.form.logo
        }
        this.$set(this, 'form', orgData)
        this.$set(this, 'showOrgRowEdit', true)
        this.$set(this, 'formEditable', false)
        this.getOrganizationList()
        this.retrieve({ id: this.id })
      }).catch((error) => {
        this.orgFilters = {
          name: formData.name
        }
        if (formData.ssn) {
          this.orgFilters.ssn = formData.ssn
        }
        if (formData.vat_number) {
          this.orgFilters.vat_number = formData.vat_number
        }
        if ('duplicated_organization' in error.response.data) {
          this.showDuplicatedForm = true
        }
      })
    },
    closeModal () {
      this.orgFilters = false
      this.showDuplicatedForm = false
    },
    goToDetailPage (evt) {
      this.orgFilters = false
      this.showDuplicatedForm = false
      if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: evt.orgId } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: evt.orgId } })
      }
    },
    openDownloadDocumentsModal () {
      this.$set(this, 'showDownloadDocumentsModal', true)
    },
    closeDownloadDocumentsModal () {
      this.$set(this, 'showDownloadDocumentsModal', false)
    },
    getSummaryDocument () {
      const fileName = `${this.$t('Riepilogo')}_${this.detail.name.replace(/ /g, '')}.xlsx`
      this.downloadSummary({ id: this.detail.id, action: 'download-summary', simpleFileName: fileName })
    },
    openCompanyRegistryModal () {
      this.$set(this, 'showCompanyRegistryModal', true)
    },
    closeCompanyRegistryModal () {
      this.$set(this, 'showCompanyRegistryModal', false)
    },
    openDownloadNominationsModal () {
      this.$set(this, 'showDownloadNominationsModal', true)
    },
    closeDownloadNominationsModal () {
      this.$set(this, 'showDownloadNominationsModal', false)
    },
    downloadRegistry (event) {
      this.downloadCompanyRegistry({ id: event.orgId, staffIds: event.selectedIdList })
    }
  }
}
</script>
