<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as OWNER_ORG_TYPES } from './store'
import { ROUTES as OWNER_ORG_ROUTES } from './router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../customer-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../supplier-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../lead-organization/router'
import { ORGANIZATION_OWNER_TYPE_CODE, ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE } from '@/const'

import OrganizationDetailMixin from '../../../components/organization/mixins/OrganizationDetailMixin'
import OwnerContactList from '../../../contact/contact/owner-contact/OwnerContactList'

export default {
  name: 'OwnerOrganizationDetail',
  mixins: [OrganizationDetailMixin],
  components: {
    'component-contact-list': OwnerContactList
  },
  data () {
    return {
      OWNER_ORG_ROUTES,
      title: 'Owner organization',
      routeNameList: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_LIST,
      routeDocumentNameList: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_DOCUMENTS,
      orgType: ORGANIZATION_OWNER_TYPE_CODE,
      orgTypeChoices: null,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: false,
      showGoToDocumentsButton: true,
      showSummaryButton: false
    }
  },
  computed: {
    ...mapGetters({
      ...OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.DETAIL.GETTERS,
      groups: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS.groups,
      organizationform: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS.organizationform,
      typologyChoices: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS.typologyChoices,
      employeeCategoryChoices: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS.employeeCategoryChoices
    })
  },
  methods: {
    ...mapMutations({
      setGroups:
        OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.MUTATIONS.setGroups
    }),
    ...mapActions({
      ...OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.DETAIL.ACTIONS,
      getOrganizationList: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getList,
      getGroups: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getGroups,
      getOrganizationform: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getOrganizationform,
      getTypologychoices: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getTypologychoices,
      getEmployeecategorychoices: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getEmployeecategorychoices
    }),
    getListOptions () {
      this.getOrganizationform()
      this.setGroups({})
      this.getTypologychoices()
      this.getEmployeecategorychoices()
    },
    goToDetailPage (evt) {
      this.orgFilters = false
      this.showDuplicatedForm = false
      if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_SUPPLIER_CODE } })
      }
    }
  }
}
</script>
