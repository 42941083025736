<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_EMAIL_TYPES } from '../store'

export default {
  name: 'EmailTypologyMixin',
  created () {
    if (!this.emailTypes || (this.emailTypes && this.emailTypes.length === 0)) {
      this.getEmailtypes()
    }
  },
  computed: {
    ...mapGetters({
      emailTypes: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.GETTERS.emailTypes
    }),
    emailTypologyOptions () {
      return this.prepareListForSelect(this.emailTypes, 'id', ['name'])
    }
  },
  methods: {
    ...mapActions({
      getEmailtypes: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.ACTIONS.getEmailtypes
    }),
    selectEmailTypology (item) {
      this.$set(this.form, 'typology', item.value)
      this.$set(this.form, 'typology_name', item.name)
    },
    clearEmailTypology () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'typology_name', null)
    }
  }
}
</script>
