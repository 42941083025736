<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/store'
import { vue } from '../../../../../main'

export default {
  name: 'OrganizationMixin',
  created () {
    this.setList({})
  },
  computed: {
    ...mapGetters({
      organizationList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list
    }),
    organizationListOptions () {
      const options = []
      if (this.organizationList && this.organizationList.results && this.organizationList.results.length > 0) {
        this.organizationList.results.forEach((organization) => {
          const option = {
            id: organization.id,
            name: organization.name,
            status: organization.status
          }
          if (organization.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getOrganizationList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    onSearchOrganization (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.filter__organization_types__category = this.orgType
        filters.order_by = '-status.-date_create'
        this.getOrganizationList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    setOrganizations (formData) {
      if (this.form.organizations) {
        const list = []
        this.form.organizations.forEach((row) => list.push({ organization: row.id }))
        this.$set(formData, 'organizations', new Set(list))
      } else {
        this.$set(formData, 'organizations', [])
      }
      return Array.from(formData.organizations)
    },
    selectOrganization (item) {
      if (!this.form.organizations) {
        this.$set(this.form, 'organizations', [])
      }
      this.form.organizations.push(item)
    },
    removeOrganization (item) {
      const organizations = this.form.organizations.filter((organization) => {
        return organization.id !== item.id
      })
      this.$set(this.form, 'organizations', organizations)
      if (this.form.organizations.length === 0) this.$set(this.form, 'organizations', null)
    }
  }
}
</script>
