<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'
import { vue } from '../../../../../main'

export default {
  name: 'ProfessionalRegisterMixin',
  created () {
    this.setProfessionalregisterlist({})
  },
  computed: {
    ...mapGetters({
      professionalRegisterList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.professionalRegisterList
    }),
    professionalRegisterOptions: function () {
      return this.prepareListForSelect(this.professionalRegisterList.results, 'id', ['name'])
    }
  },
  methods: {
    ...mapMutations({
      setProfessionalregisterlist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setProfessionalregisterlist
    }),
    ...mapActions({
      getProfessionalregisterlist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getProfessionalregisterlist
    }),
    onSearchProfessionalRegister (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getProfessionalregisterlist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProfessionalregisterlist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectProfessionalRegister (item) {
      this.$set(this.row, 'professional_register', item.value)
      this.$set(this.row, 'professional_register_name', item.name)
    },
    clearProfessionalRegister () {
      this.$set(this.row, 'professional_register', null)
      this.$set(this.row, 'professional_register_name', null)
    }
  }
}
</script>
