<script>
export default {
  name: 'OrganizationFormMixin',
  props: {
    orgFormList: { required: true }
  },
  computed: {
    organizationFormListOptions () {
      if (this.orgFormList && this.orgFormList.length > 0) {
        return this.prepareListForMultiSelect(this.orgFormList, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    selectOrganizationForm (item) {
      this.$set(this.form, 'organization_form', item.id)
      this.$set(this.form, 'organization_form_name', item.name)
    },
    clearOrganizationForm () {
      this.$set(this.form, 'organization_form', null)
      this.$set(this.form, 'organization_form_name', null)
    }
  }
}
</script>
