<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as OWNER_CONTACT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ContactTableMixin from '../../../components/contact/mixins/ContactTableMixin'

export default {
  name: 'OwnerContactTable',
  mixins: [ContactTableMixin],
  created () {
    if (!this.params.orgId) {
      delete (this.fields[5])
      delete (this.fields[7])
      delete (this.fields[9])
      delete (this.fields[10])
      delete (this.fields[11])
    }
  },
  computed: {
    ...mapGetters({
      selectAll: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.GETTERS.selectAll,
      selectedList: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.GETTERS.selectedList
    }),
    showDetailButton () {
      return hasPerm('organization_ownercontact_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setList,
      setSelectedList:
        OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.ACTIONS
    })

  }
}
</script>
