<script>
export default {
  name: 'OrganizationCategoryEmployeeMixin',
  props: {
    orgEmployeeCatList: { required: true }
  },
  computed: {
    organizationEmployeeCategoryOptions () {
      if (this.orgEmployeeCatList && this.orgEmployeeCatList.length > 0) {
        return this.prepareChoiceListForSelect(this.orgEmployeeCatList, 0, 1)
      }
      return []
    }
  },
  methods: {
    selectOrganizationEmployeeCategory (item) {
      this.$set(this.form, 'organization_category', item.value)
      this.$set(this.form, 'organization_category_name', item.name)
    },
    clearOrganizationEmployeeCategory () {
      this.$set(this.form, 'organization_category', null)
      this.$set(this.form, 'organization_category_name', null)
    }
  }
}
</script>
