<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showEditButton="showEditButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-edit-form="$emit('open-edit-form', $event)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_EMAIL_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'OrganizationEmailTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'typology__name',
          label: this.$t('Email Type'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'typology_name'
        },
        {
          key: 'email',
          label: this.$t('Email'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'email'
        },
        {
          key: 'note',
          label: this.$t('Note'),
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          sortable: true,
          value: 'note'
        },
        {
          key: 'is_primary',
          label: this.$t('Primary Email'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          type: 'boolean',
          value: 'is_primary'
        },
        {
          key: 'is_for_training',
          label: this.$t('Training Email'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          type: 'boolean',
          value: 'is_for_training'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'typology__name', 'email', 'is_primary']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.GETTERS.selectAll,
      selectedList: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showEditButton () {
      return hasPerm('organization_organizationemail_update')
    }
  },
  methods: {
    ...mapMutations({
      setList: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.MUTATIONS.setList,
      setSelectedList:
        ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.ACTIONS
    })
  }
}
</script>
