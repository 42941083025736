<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as BANK_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import BankTable from './BankTable'
import BankEdit from './BankEdit'

export default {
  name: 'BankList',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': BankTable,
    'component-edit': BankEdit
  },
  data () {
    return {
      title: this.$t('Banks'),
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ],
      showHeader: false,
      showCommonFilters: false,
      rolePerm: 'bank_bank_list',
      actionEnablePermission: ['bank_bank_enable'],
      actionDisablePermission: ['bank_bank_disable']
    }
  },
  computed: {
    ...mapGetters({
      ...BANK_TYPES.GENERIC.bank.bank.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('bank_bank_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: BANK_TYPES.GENERIC.bank.bank.LIST.MUTATIONS.setSelectedList,
      setSelectAll: BANK_TYPES.GENERIC.bank.bank.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...BANK_TYPES.GENERIC.bank.bank.LIST.ACTIONS
    }),
    getComponentList () {
      if (this.orgId) {
        this.setFilters({ filter__organization__id: this.orgId })
      } else {
        this.setFilters({ })
      }
    },
    openEditForm (id) {
      this.$set(this.editParams, 'orgId', this.orgId)
      if (id) {
        this.$set(this.editParams, 'bankId', id)
      } else {
        this.$set(this.editParams, 'bankId', undefined)
      }
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
