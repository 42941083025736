<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalDuplicatedOrganization"
    ref="modalDuplicatedOrganization"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalDuplicatedOrganizationOnHidden"
  >
    <div slot="modal-title">
      {{ title | translate }}
    </div>
    <div class="mt-3 mb-3">
      {{resultMessage}}
    </div>
    <div class="mb-3">
      {{message}}
    </div>
    <b-table
        no-local-sorting
        show-empty
        striped
        hover
        responsive
        :items="orgList"
        :fields="fields"
      >
      <template slot="cell(status)" slot-scope="row">
        <status-indicator :status="row.item.status" />
      </template>
      <template slot="cell(is_customer)" slot-scope="row">
        <status-indicator :status="row.item.is_customer && !row.item.is_lead" />
      </template>
      <template slot="cell(is_supplier)" slot-scope="row">
        <status-indicator :status="row.item.is_supplier" />
      </template>
      <template slot="cell(is_lead)" slot-scope="row">
        <status-indicator :status="row.item.is_lead" />
      </template>

      <template slot="cell(checks)" slot-scope="data">
        <thux-table-checks-component
          :select-all="false"
          :value="selectedItemId === data.item.id"
          @set-selected-row="setSelectedRow(data.item)"
        />
      </template>
    </b-table>
    <div class="mt-3">
      {{selectMessage}}
    </div>
    <div class="mt-3">
      {{questionMessage}}
    </div>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToForm()">
        {{ 'Change Data' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndGoToDetail()"
        :disabled="!selectedItemId"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as ORG_TYPES } from '../../../organization/store'
import StatusIndicator from '@/components/StatusIndicator'
import ThuxTableChecksComponent from '@/components/thux-table/thux-table-checks/ThuxTableChecksComponent'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

export default {
  name: 'DuplicatedOrganizationModal',
  props: ['orgFilters', 'orgType', 'orgId'],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'status'
        },
        {
          key: 'code',
          label: this.$t('Code'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          value: 'code'
        },
        {
          key: 'name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          value: 'name'
        },
        {
          key: 'vat_number',
          label: this.$t('VAT number'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'vat_number'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'ssn'
        },
        {
          key: 'is_customer',
          label: this.$t('Customer'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'is_customer'
        },
        {
          key: 'is_supplier',
          label: this.$t('Supplier'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'is_supplier'
        },
        {
          key: 'is_lead',
          label: this.$t('Extra'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'is_lead'
        }
      ],
      selectMessage: this.$t('If the company you want to add is present in the previous list, press the OK button to go to detail page.'),
      selectedItemId: undefined,
      selectedOrgTypes: []
    }
  },
  components: {
    StatusIndicator,
    ThuxTableChecksComponent
  },
  created () {
    const filters = {}
    Object.keys(this.orgFilters).forEach(key => {
      filters[`filter__${key}__iexact`] = this.orgFilters[key]
    })
    filters.no_page = 'no_page'
    if (this.orgId) {
      filters.exclude__id = this.orgId
    }
    this.setOrgFilters(filters)
  },
  mounted () {
    this.openModalDuplicatedOrganization()
  },
  computed: {
    ...mapGetters({ list: ORG_TYPES.GENERIC.organization.organization.LIST.GETTERS.list }),
    title () {
      return this.$t('Organization already exist')
    },
    message () {
      let msg = `${this.$t('Organization with business name')} ${this.orgFilters.name} `
      if (this.orgFilters.ssn) {
        msg = `${msg}, ${this.$t('ssn')} ${this.orgFilters.ssn}`
      }
      if (this.orgFilters.vat_number) {
        msg = `${msg}, ${this.$t('vat number')} ${this.orgFilters.vat_number}`
      }
      msg = `${msg} ${this.$t('already present in the system')}`
      return msg
    },
    resultMessage () {
      return `${this.$t('It is not possible')} ${this.orgId ? this.$t('to edit the organization') : this.$t('to create a new organization')} ${this.$t('with the data entered')}.`
    },
    questionMessage () {
      const neededKeys = ['name', 'ssn', 'vat_number']
      if (neededKeys.every(key => Object.keys(this.orgFilters).includes(key))) {
        return `${this.$t('If not, if you want to change the data and')} ${this.orgId ? this.$t('edit the organization') : this.$t('create a new organization')}, ${this.$t('press the "Change Data" button')}.`
      }
      if (!('ssn' in this.orgFilters || 'vat_number' in this.orgFilters)) {
        return `${this.$t('If not, SSN and Vat number are not inserted')}. ${this.$t('If you want to specify them and')} ${this.orgId ? this.$t('edit the organization') : this.$t('create a new organization')}, ${this.$t('press the "Change Data" button')}.`
      }
      if (!('ssn' in this.orgFilters)) {
        return `${this.$t('If not, SSN is not inserted')}. ${this.$t('If you want to specify it and')} ${this.orgId ? this.$t('edit the organization') : this.$t('create a new organization')}, ${this.$t('press the "Change Data" button')}.`
      }
      if (!('vat_number' in this.orgFilters)) {
        return `${this.$t('If not, VAT number is not inserted')}. ${this.$t('If you want to specify it and')} ${this.orgId ? this.$t('edit the organization') : this.$t('create a new organization')}, ${this.$t('press the "Change Data" button')}.`
      }
      return ''
    },
    showCancel () {
      const neededKeys = ['name', 'ssn', 'vat_number']
      if (neededKeys.every(key => Object.keys(this.orgFilters).includes(key))) {
        return false
      }
      return true
    },
    orgList () {
      if (Array.isArray(this.list)) {
        return this.list
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      setOrgFilters: ORG_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    openModalDuplicatedOrganization () {
      this.$refs.modalDuplicatedOrganization.show()
    },
    modalDuplicatedOrganizationOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToForm (evt) {
      this.$emit('close-modal')
    },
    setSelectedRow (item) {
      if (item.id === this.selectedItemId) {
        this.selectedItemId = undefined
        this.selectedOrgTypes = []
      } else {
        this.selectedItemId = item.id
        if (item.is_lead) {
          this.selectedOrgTypes.push(ORGANIZATION_CUSTOMER_LEAD_CODE)
        } else {
          if (item.is_customer) {
            this.selectedOrgTypes.push(ORGANIZATION_CUSTOMER_TYPE_CODE)
          }
          if (item.is_supplier) {
            this.selectedOrgTypes.push(ORGANIZATION_SUPPLIER_TYPE_CODE)
          }
        }
      }
    },
    closeAndGoToDetail (evt) {
      this.$emit('close-modal-and-go-to-detail', { orgId: this.selectedItemId, orgTypes: this.selectedOrgTypes })
    }
  }
}
</script>
