<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'INAIL Code' | translate"
            class-form="col-12 mb-2"
            labelColsSm="2"
            labelColsLg="2"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.staff_type"
            :external-errors="errors['staff_type']"
            :label-form="'Staff typology' | translate"
            class-form="col-12 mb-2 mt-2"
            labelColsSm="2"
            labelColsLg="2"
            :disabled="!formEditable"
            label-search="name"
            :customize-option="true"
            :value="form.staff_type ? { value: form.staff_type, name: form.staff_type_name } : null"
            :options="staffTypeListOptions"
            @select="selectStaffType"
            @clear="clearStaffType"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as INAIL_CODE_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import StaffTypeMixin from './mixins/StaffTypeMixin'

export default {
  name: 'InailCodeEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, StaffTypeMixin],
  data () {
    return {
      pageName: 'INAIL PAT',
      rolePerm: 'organization_inailcode_retrieve',
      editRolePerm: 'organization_inailcode_update',
      patId: undefined,
      orgId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...INAIL_CODE_TYPES.GENERIC.organization.inailcode.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...INAIL_CODE_TYPES.GENERIC.organization.inailcode.DETAIL.ACTIONS
    }),
    initFields () {
      this.$set(this, 'patId', this.id)
      this.$set(this, 'orgId', this.editParams.orgId)
      this.getStafftypes()
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.patId) {
        formData.organization = this.orgId
      }
      return formData
    }
  },
  validations () {
    const values = {
      form: {
        staff_type: {},
        code: { required }
      }
    }
    if (this.form.staff_type) {
      values.form.code = { required }
    }
    return values
  }
}
</script>
