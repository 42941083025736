<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="organizations" slot-scope="row">
        <div v-if="row.item.organizations">
          <span
            v-for="organization in row.item.organizations"
            :key="organization.id"
            class="tag"
          >
            {{ organization.name }}
          </span>
        </div>
      </template>
      <template slot="org_roles" slot-scope="row">
        <span
            v-for="role in getRoleList(row.item.organization_contacts)"
            :key="role"
            class="tag"
          >
            {{ role }}
          </span>
      </template>
      <template slot="security_roles" slot-scope="row">
        <span
            v-for="role in getSecurityRoleList(row.item.organization_contacts_security_roles)"
            :key="role"
            class="tag"
          >
            {{ role }}
          </span>
      </template>
      <template slot="staff_type_name" slot-scope="row">
          {{ getStaffTypeName(row.item.organization_contacts) }}
      </template>
      <template slot="qualification_str" slot-scope="row">
          {{ getQualificationName(row.item.organization_contacts) | truncate}}
      </template>
      <template slot="date_next_medical_exam" slot-scope="row">
          <span :class="isNextMedicalExamPassed(row.item.organization_contacts) ? 'text-red': '' ">{{ getDateNextMedicalExam(row.item.organization_contacts) | date}}</span>
      </template>
      <template slot="contract_typology_name" slot-scope="row">
          {{ getContractTypologyName(row.item.organization_contacts) }}
      </template>
      <template slot="contract_end" slot-scope="row">
          <span :class="isContractEndDateExamPassed(row.item.organization_contacts) ? 'text-red': '' ">{{ getDateContractEnd(row.item.organization_contacts) | date}}</span>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="createPrivateOrganizationRolePerm"
          :title="'Create private organization' | translate"
          @click.prevent="openCreateOrganizationModal(row.item)"
        >
          <i class="far fa-building"></i>
        </b-btn>
        <b-btn
          v-if="$route.params.orgType"
          variant="outline-primary"
          v-has-perms="showContactDocumentsRolePerm"
          :title="'Show contact documents' | translate"
          :to="getContactDocumentsRouter(row.item.id, `${row.item.last_name} ${row.item.first_name}`)"
        >
          <i class="far fa-file-alt"></i>
        </b-btn>
        <b-btn
          v-if="params.orgId"
          variant="outline-primary"
          v-has-perms="changeOrganizationContactStatusRolePerm"
          :title="getButtonTitle(row.item)"
          @click="changeOrganizationContactStatus(row.item)"
        >
          <i :class="getButtonIconClass(row.item)"></i>
        </b-btn>
        <b-btn
          v-if="params.orgId"
          variant="outline-primary"
          v-has-perms="printWorkerCardRolePerm"
          :title="'print card' | translate"
          @click="printWorkerCard(row.item)"
        >
          <i class="fas fa-id-card"></i>
        </b-btn>
        <b-btn
          v-if="showSubscribeToCoursesButton"
          variant="outline-primary"
          :title="'Subscribe to courses' | translate"
          v-has-perms="subscribeToCourseRolePerm"
          @click.prevent="openSubscribeToCourseModal(row.item)"
        >
          <i class="fas fa-graduation-cap"></i>
        </b-btn>
      </template>
    </thux-table>
    <create-organization-from-contact-modal
      v-if="showCreateOrganizationForm"
      :contact="selectedContact"
      v-on:close-modal="closeModal"
      v-on:close-modal-and-create="createOrganization"
    >
    </create-organization-from-contact-modal>
    <subscribe-to-course-modal
      v-if="showSubscribeToCourseModal"
      :contact="selectedContact"
      :orgId="params.orgId"
      v-on:close-modal="closeSubscribeToCourseModal"
      v-on:close-modal-and-subscribe="subscribeContactToCourse"
    >
    </subscribe-to-course-modal>
  </div>
</template>

<script>
import ThuxTableMixin from '../../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_TYPES } from '../../../organization/store'
import { TYPES as ORG_CNT_TYPES } from '../../../organization-contact/store'
import { TYPES as CNT_ADDR_TYPES } from '../../../contact/contact-address/store'
import { TYPES as CNT_EMAIL_TYPES } from '../../../contact/contact-email/store'
import { TYPES as CNT_PHONE_TYPES } from '../../../contact/contact-phone/store'
import { TYPES as CONTACT_TYPES } from '../../../contact/store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../../organization/organization/customer-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../../organization/organization/supplier-organization/router'
import { ROUTES as EXTRA_ORG_ROUTES } from '../../../organization/organization/lead-organization/router'
import { ROUTES as CUSTOMER_CONTACT_ROUTES } from '../../../contact/contact/customer-contact/router'
import { ROUTES as SUPPLIER_CONTACT_ROUTES } from '../../../contact/contact/supplier-contact/router'
import { ROUTES as LEAD_CONTACT_ROUTES } from '../../../contact/contact/lead-contact/router'
import { ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_SUPPLIER_CODE } from '@/const'
import CreateOrganizationFromContactModal from '../contact-modal/CreateOrganizationFromContactModal'
import SubscribeToCourseModal from '../contact-modal/SubscribeToCourseModal'

export default {
  name: 'ContactTableMixin',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    CreateOrganizationFromContactModal,
    SubscribeToCourseModal
  },
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'last_name'
        },
        {
          key: 'first_name',
          label: this.$t('First name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'first_name'
        },
        {
          key: 'staff_type_name',
          label: this.$t('Staff type'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'org_roles',
          label: this.$t('Roles'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'qualification_str',
          label: this.$t('Qualification'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'security_roles',
          label: this.$t('Security roles'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap',
          custom: true
        },
        {
          key: 'date_next_medical_exam',
          label: this.$t('Date next medical exam'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'contract_typology_name',
          label: this.$t('Contract typology'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'contract_end',
          label: this.$t('Contract date end'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'id', 'last_name', 'first_name', 'ssn', 'birth_date', 'birth_place'],
      showCreateOrganizationForm: false,
      selectedContact: undefined,
      showSubscribeToCoursesButton: true,
      showSubscribeToCourseModal: false,
      CUSTOMER_CONTACT_ROUTES,
      SUPPLIER_CONTACT_ROUTES,
      LEAD_CONTACT_ROUTES,
      ORGANIZATION_CUSTOMER_CODE,
      ORGANIZATION_SUPPLIER_CODE,
      ORGANIZATION_CUSTOMER_LEAD_CODE,
      // Permissions
      createPrivateOrganizationRolePerm: 'organization_organization_create',
      showContactDocumentsRolePerm: 'document_uploaddocument_list',
      subscribeToCourseRolePerm: 'organization_contact_subscribe_training',
      printWorkerCardRolePerm: 'organization_organizationcontact_print_card_pdf',
      changeOrganizationContactStatusRolePerm: 'organization_organizationcontact_set_status'
    }
  },
  created () {},
  computed: {
    ...mapGetters({
      orgDetail: ORG_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return true
    }
  },
  methods: {
    ...mapActions({
      createPrivateOrganizationFromContact: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.create,
      createOrganizationContact: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create,
      setOrganizationContactStatus: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.setStatus,
      createOrganizationAddress: CNT_ADDR_TYPES.GENERIC.organization.contactaddress.DETAIL.ACTIONS.createOrganizationAddress,
      organizationContactCardDownload: ORG_CNT_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.download,
      createOrganizationEmail: CNT_EMAIL_TYPES.GENERIC.organization.contactemail.DETAIL.ACTIONS.createOrganizationEmail,
      createOrganizationPhone: CNT_PHONE_TYPES.GENERIC.organization.contactphone.DETAIL.ACTIONS.createOrganizationPhone,
      subscribeToCourse: CONTACT_TYPES.GENERIC.organization.contact.DETAIL.ACTIONS.subscribeToCourse
    }),
    getStaffTypeName (items) {
      let res = ''
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            res = item.staff_type_name
          }
        }
      }
      return res
    },
    getQualificationName (items) {
      let res = ''
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            res = item.qualification_str
          }
        }
      }
      return res
    },
    getRoleList (items) {
      const res = []
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            item.roles.forEach(element => {
              res.push(element.name)
            })
          }
        } else {
          items.forEach(element => {
            element.roles.forEach(role => {
              res.push(role.name)
            })
          })
        }
      }
      return [...new Set(res)]
    },
    getSecurityRoleList (items) {
      const res = []
      if (items) {
        if (this.params.orgId) {
          items.forEach(element => {
            if (element.organization === parseInt(this.params.orgId)) {
              res.push(element.security_role.name)
            }
          })
        } else {
          items.forEach(element => {
            res.push(element.security_role.name)
          })
        }
      }
      return [...new Set(res)]
    },
    getDateNextMedicalExam (items) {
      let res = ''
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            res = item.date_next_medical_exam
          }
        }
      }
      return res
    },
    getContractTypologyName (items) {
      let res = ''
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            res = item.contract_type_name
          }
        }
      }
      return res
    },
    getDateContractEnd (items) {
      let res = ''
      if (items) {
        if (this.params.orgId) {
          const item = items.find(el => el.organization === parseInt(this.params.orgId))
          if (item) {
            res = item.contract_end
          }
        }
      }
      return res
    },
    openCreateOrganizationModal (item) {
      this.selectedContact = item
      this.showCreateOrganizationForm = true
    },
    closeModal () {
      this.selectedContact = undefined
      this.showCreateOrganizationForm = false
    },
    createOrganization (evt) {
      const addresses = this.selectedContact.address_ids
      const emails = this.selectedContact.email_ids
      const phones = this.selectedContact.phone_ids
      console.log(addresses)
      this.selectedContact = undefined
      this.showCreateOrganizationForm = false
      const orgData = {
        name: `${evt.last_name} ${evt.first_name}`,
        organization_types: evt.organization_types,
        ssn: evt.ssn,
        typology: 'PR'
      }
      this.createPrivateOrganizationFromContact(orgData).then(
        () => {
          const organizationContactData = {
            organization: this.orgDetail.id,
            contact: evt.id
          }
          const promises = []
          promises.push(this.createOrganizationContact(organizationContactData))

          addresses.forEach(id => {
            promises.push(this.createOrganizationAddress({ id: id, organization_id: this.orgDetail.id }))
          })
          emails.forEach(id => {
            promises.push(this.createOrganizationEmail({ id: id, organization_id: this.orgDetail.id }))
          })
          phones.forEach(id => {
            promises.push(this.createOrganizationPhone({ id: id, organization_id: this.orgDetail.id }))
          })
          Promise.all(promises).then(
            () => {
              if (this.orgDetail.is_lead) {
                this.$router.push({ name: EXTRA_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { orgType: ORGANIZATION_CUSTOMER_LEAD_CODE, id: this.orgDetail.id } })
              } else if (this.orgDetail.is_supplier) {
                this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { orgType: ORGANIZATION_SUPPLIER_CODE, id: this.orgDetail.id } })
              } else {
                this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { orgType: ORGANIZATION_CUSTOMER_CODE, id: this.orgDetail.id } })
              }
            }
          )
        }
      )
    },
    openSubscribeToCourseModal (item) {
      this.selectedContact = item
      this.showSubscribeToCourseModal = true
    },
    closeSubscribeToCourseModal () {
      this.selectedContact = undefined
      this.showSubscribeToCourseModal = false
    },
    subscribeContactToCourse (evt) {
      // TODO
      console.log(evt)
      const formData = {}
      formData.id = evt.contact
      formData.organization_id = evt.organization
      formData.max_date = evt.max_date
      formData.sku = []
      evt.products.forEach(el => {
        formData.sku.push(el.sku)
      })
      this.subscribeToCourse(formData).then(
        () => {
          this.selectedContact = undefined
          this.showSubscribeToCourseModal = false
        }
      )
    },
    getContactDocumentsRouter (cntId, name) {
      if (this.$route.params.orgType && this.$route.params.orgType === ORGANIZATION_SUPPLIER_CODE) {
        if (this.params.orgId) {
          return {
            name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DOCUMENTS,
            params: { orgId: this.params.orgId, cntId: cntId, orgType: ORGANIZATION_SUPPLIER_CODE, cntName: name }
          }
        } else {
          return { name: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DOCUMENT_LIST, params: { orgType: ORGANIZATION_SUPPLIER_CODE, cntId: cntId, cntName: name } }
        }
      } else if (this.$route.params.orgType && this.$route.params.orgType === ORGANIZATION_CUSTOMER_LEAD_CODE) {
        if (this.params.orgId) {
          return {
            name: EXTRA_ORG_ROUTES.LEAD_ORGANIZATION_CONTACT_DOCUMENT_LIST,
            params: { orgId: this.params.orgId, cntId: cntId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE, cntName: name }
          }
        } else {
          return { name: LEAD_CONTACT_ROUTES.LEAD_CONTACT_DOCUMENT_LIST, params: { orgType: ORGANIZATION_CUSTOMER_LEAD_CODE, cntId: cntId, cntName: name } }
        }
      } else {
        if (this.params.orgId) {
          return {
            name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_CONTACT_DOCUMENTS,
            params: { orgId: this.params.orgId, cntId: cntId, orgType: ORGANIZATION_CUSTOMER_CODE, cntName: name }
          }
        } else {
          return { name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DOCUMENT_LIST, params: { orgType: ORGANIZATION_CUSTOMER_CODE, cntId: cntId, cntName: name } }
        }
      }
    },
    isNextMedicalExamPassed (item) {
      const date = this.getDateNextMedicalExam(item)
      if (date) {
        if (new Date() > new Date(date)) {
          return true
        }
      }
      return false
    },
    isContractEndDateExamPassed (item) {
      const date = this.getDateContractEnd(item)
      if (date) {
        if (new Date() > new Date(date)) {
          return true
        }
      }
      return false
    },
    getButtonTitle (item) {
      const orgCnt = item.organization_contacts.find(el => el.organization === parseInt(this.params.orgId))
      if (orgCnt && orgCnt.status === 1) {
        return this.$t('Remove from staff')
      }
      return this.$t('Add to staff')
    },
    getButtonIconClass (item) {
      const orgCnt = item.organization_contacts.find(el => el.organization === parseInt(this.params.orgId))

      if (orgCnt && orgCnt.status === 1) {
        return 'fas fa-user-minus'
      }
      return 'fas fa-user-plus'
    },
    changeOrganizationContactStatus (item) {
      const orgCnt = item.organization_contacts.find(el => el.organization === parseInt(this.params.orgId))
      this.setOrganizationContactStatus({ id: orgCnt.id, status: orgCnt.status === 1 ? 0 : 1 }).then(
        () => {
          const index = this.list.findIndex(obj => obj.id === item.id)
          this.list.splice(index, 1)
        }
      )
    },
    printWorkerCard (item) {
      const orgCnt = item.organization_contacts.find(x => (x.contact === item.id && x.organization === parseInt(this.params.orgId)))
      const fileName = `${item.last_name}_${item.first_name}.pdf`
      this.organizationContactCardDownload({ id: orgCnt.id, action: 'print-card-pdf', simpleFileName: fileName })
    }
  }
}
</script>
