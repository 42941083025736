<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-multiselect
            :validator="$v.form.address_scopes"
            label-form="Address scope"
            label-form-class="mb-2"
            class-form="col-6 mb-2 mt-2"
            :external-errors="errors['address_scopes']"
            label-search="name"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="form.address_scopes"
            :options="addressScopeListOptions"
            :placeholder="
              $t('Search or select a {instance}', { instance: $t('address scope') })
            "
            @remove="removeAddressScope"
            @select="selectAddressScope"
            @clear="$set(form, 'address_scopes', null)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.org_name"
            :external-errors="errors['org_name']"
            :label-form="'Branch Name' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.org_name"
            :disabled="!formEditable"
            @change="$set(form, 'org_name', $event)"
         />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.address_type"
            :external-errors="errors['address_type']"
            :label-form="'Address Type' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'name'"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.address_type ? { value: form.address_type, name: form.address_type_name } : null"
            :options="addressTypeListOptions"
            :placeholder="$t('Select a address type')"
            :placeholder-field="form.address_type_name"
            @select="selectAddressType"
            @clear="clearAddressType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Address' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.number"
            :external-errors="errors['number']"
            :label-form="'N.' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.number"
            :disabled="!formEditable"
            @change="$set(form, 'number', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.zip_code"
            :external-errors="errors['zip_code']"
            :label-form="'Zip code' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.zip_code"
            :disabled="!formEditable"
            @change="$set(form, 'zip_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.locality"
            :external-errors="errors['locality']"
            :label-form="'Locality' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.locality"
            :disabled="!formEditable"
            @change="$set(form, 'locality', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.province"
            :external-errors="errors['province']"
            :label-form="'Province (abbr.)' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.province"
            :disabled="!formEditable"
            @change="$set(form, 'province', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.country"
            :external-errors="errors['country']"
            :label-form="'Country' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="form.country ? { value: form.country, name: form.country_name } : null"
            :options="countryListOptions"
            :placeholder="$t('Type a country')"
            :placeholder-field="form.country_name"
            @select="selectCountry"
            @clear="clearCountry"
          />
          <form-thux-horizontal-text-area
            :validator="$v.form.note"
            :external-errors="errors['note']"
            :label-form="'Note' | translate"
            class-form="col-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            rows="3"
            max-rows="6"
            :value="form.note"
            :disabled="!formEditable"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import CountryMixin from './mixins/CountryMixin'
import AddressScopeMixin from './mixins/AddressScopeMixin'
import AddressTypeMixinVue from './mixins/AddressTypeMixin'

export default {
  name: 'OrganizationAddressEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, CountryMixin, AddressScopeMixin, AddressTypeMixinVue],
  data () {
    return {
      pageName: 'Address',
      rolePerm: 'organization_organizationaddress_retrieve',
      editRolePerm: 'organization_organizationaddress_update',
      addrId: undefined,
      orgId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.DETAIL.ACTIONS
    }),
    initFields () {
      this.$set(this, 'addrId', this.id)
      this.$set(this, 'orgId', this.editParams.orgId)
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(form, 'address_scopes', this.getAddressScopes(form))
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.addrId) {
        formData.organization = this.orgId
      }
      this.setAddressScopes(formData)
      return formData
    }
  },
  validations: {
    form: {
      address_scopes: {
        required
      },
      address_type: {},
      org_name: {},
      name: {
        required
      },
      number: {
        maxLength: maxLength(20)
      },
      zip_code: {
        maxLength: maxLength(20)
      },
      locality: {
        required
      },
      province: {
        maxLength: maxLength(2)
      },
      country: {
        required
      },
      note: {}
    }
  }
}
</script>
