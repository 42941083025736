<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'

export default {
  name: 'LiquidationStatusMixin',
  created () {
    this.getLiquidationstatuschoices()
  },
  computed: {
    ...mapGetters({
      liquidationStatusList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.liquidationStatusChoices
    }),
    organizationLiquidationStatusOptions () {
      const enabledList = []
      if (this.liquidationStatusList && Array.isArray(this.liquidationStatusList)) {
        this.liquidationStatusList.forEach(function (element) {
          enabledList.push({ value: element[0], name: element[1] })
        })
      }
      return enabledList
    }
  },
  methods: {
    ...mapMutations({
      setLiquidationstatuschoices: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setLiquidationstatuschoices
    }),
    ...mapActions({
      getLiquidationstatuschoices: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getLiquidationstatuschoices
    }),
    selectOrganizationLiquidationStatus (item) {
      this.$set(this.row, 'liquidation_status', item.value)
      this.$set(this.row, 'get_liquidation_status_display', item.name)
    },
    clearOrganizationLiquidationStatus () {
      this.$set(this.row, 'liquidation_status', null)
      this.$set(this.row, 'get_liquidation_status_display', null)
    }
  }
}
</script>
