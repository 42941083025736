<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'
import { vue } from '../../../../../main'

export default {
  name: 'CclnMixin',
  created () {
    this.setCcnllist({})
  },
  computed: {
    ...mapGetters({
      ccnlList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.ccnlList
    }),
    ccnlOptions () {
      if (this.ccnlList) {
        return this.prepareListForSelect(this.ccnlList.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setCcnllist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setCcnllist
    }),
    ...mapActions({
      getCcnllist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getCcnllist
    }),
    onSearchCcnl (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getCcnllist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setCcnllist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectCcnl (item) {
      this.$set(this.row, 'ccnl', item.value)
      this.$set(this.row, 'ccnl_name', item.name)
    },
    clearCcnl () {
      this.$set(this.row, 'ccnl', null)
      this.$set(this.row, 'ccnl_name', null)
    }
  }
}
</script>
