<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as OWNER_CONTACT_TYPES } from './store'
import { ROUTES as OWNER_ORG_ROUTES } from '../../../organization/organization/owner-organization/router'
import { hasPerm } from '@/components/PermissionHelper'

import OwnerContactTable from './OwnerContactTable'
import OwnerContactCreate from './OwnerContactCreate'
import OwnerContactDetail from './OwnerContactDetail'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'

export default {
  name: 'OwnerContactList',
  props: ['orgId'],
  mixins: [ContactListMixin],
  components: {
    'component-table': OwnerContactTable,
    'component-edit': OwnerContactCreate,
    'component-detail': OwnerContactDetail
  },
  data () {
    return {
      OWNER_ORG_ROUTES,
      title: this.$t('Contacts'),
      rolePerm: ['organization_contact_list'],
      actionEnablePermission: ['organization_contact_enable'],
      actionDisablePermission: ['organization_contact_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: [],
      detailComponent: false
    }
  },
  computed: {
    ...mapGetters({
      ...OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contact_create')
    },
    showList () {
      return this.$route.name === this.OWNER_ORG_ROUTES.OWNER_ORGANIZATION_DETAIL
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__organizations__id: this.orgId })
    },
    openDetailForm (cntId) {
      this.$emit('show-contact-detail', true)
      this.$router.push({
        name: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_CONTACT_DETAIL, params: { orgId: this.orgId, cntId: cntId }
      })
    }
  }
}
</script>
