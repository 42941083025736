<script>
import { mapActions, mapGetters } from 'vuex'
import { ORGANIZATION_OWNER_TYPE_CODE } from '@/const'
import { TYPES as OWNER_CONTACT_TYPES } from './store'
import { TYPES as CNT_ORG_TYPES } from '../../../organization-contact/store'
import { ROUTES as OWNER_ORG_ROUTES } from '../../../organization/organization/owner-organization/router'
// import ContactCreateForm from '../components/contact/ContactCreateForm'
import ContactCreateMixin from '../../../components/contact/mixins/ContactCreateMixin'
// import DuplicatedContactModal from '../components/contact/DuplicatedContactModal'

export default {
  name: 'OwnerContactCreate',
  mixins: [ContactCreateMixin],
  data () {
    return {
      orgId: this.$route.params.id,
      id: this.$route.params.cntId,
      orgType: ORGANIZATION_OWNER_TYPE_CODE,
      OWNER_ORG_ROUTES,
      ORGANIZATION_OWNER_TYPE_CODE,
      showDuplicatedContactModal: false
    }
  },
  computed: {
    ...mapGetters({
      ...OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.DETAIL.GETTERS
    })
  },
  components: {
    // ContactCreateForm
    // DuplicatedContactModal
  },
  methods: {
    ...mapActions({
      ...OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.DETAIL.ACTIONS,
      getContactList: OWNER_CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.ACTIONS.getList,
      createOrganizationContact: CNT_ORG_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create
    }),
    onCancel () {
      this.$emit('close-form')
    }
  }
}
</script>
