<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as INAIL_CODE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import inailcodeTable from './InailCodeTable'
import inailcodeEdit from './InailCodeEdit.vue'

export default {
  name: 'InailCodeList',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': inailcodeTable,
    'component-edit': inailcodeEdit
  },
  data () {
    return {
      title: this.$t('PAT INAIL'),
      rolePerm: ['organization_inailcode_list'],
      actionEnablePermission: ['organization_inailcode_enable'],
      actionDisablePermission: ['organization_inailcode_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: [
        {
          key: 'filter__status',
          placeholder: this.$t('Status'),
          type: 'boolean',
          options: [
            { value: 1, text: this.$t('ENABLED') },
            { value: 0, text: this.$t('DISABLED') }
          ],
          col: 2
        },
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 5 },
        { key: 'filter__staff_type__name__icontains', placeholder: this.$t('Staff typology'), type: 'text', col: 5 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...INAIL_CODE_TYPES.GENERIC.organization.inailcode.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_inailcode_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: INAIL_CODE_TYPES.GENERIC.organization.inailcode.LIST.MUTATIONS.setSelectedList,
      setSelectAll: INAIL_CODE_TYPES.GENERIC.organization.inailcode.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...INAIL_CODE_TYPES.GENERIC.organization.inailcode.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__organization__id: this.orgId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'orgId', this.orgId)
      if (id) {
        this.$set(this.editParams, 'patId', id)
      } else {
        this.$set(this.editParams, 'patId', undefined)
      }
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
