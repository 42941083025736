<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import OrganizationPhoneEdit from './OrganizationPhoneEdit.vue'
import { TYPES as ORG_PHONE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'
import OrganizationPhoneTable from './OrganizationPhoneTable'

export default {
  name: 'OrganizationPhoneList',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationPhoneTable,
    'component-edit': OrganizationPhoneEdit
  },
  data () {
    return {
      title: this.$t('Organization Phones'),
      rolePerm: ['organization_organizationphone_list'],
      actionEnablePermission: ['organization_organizationphone_enable'],
      actionDisablePermission: ['organization_organizationphone_disable'],
      showHeader: false,
      showCommonFilters: false,

      showEmailEdit: false,
      phoneId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_PHONE_TYPES.GENERIC.organization.organizationphone.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationphone_create')
    }
  },
  methods: {
    ...mapActions({
      ...ORG_PHONE_TYPES.GENERIC.organization.organizationphone.LIST.ACTIONS
    }),
    ...mapMutations({
      setSelectedList: ORG_PHONE_TYPES.GENERIC.organization.organizationphone.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_PHONE_TYPES.GENERIC.organization.organizationphone.LIST.MUTATIONS.setSelectAll
    }),
    getComponentList () {
      this.setFilters({ filter__organization__id: this.orgId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'orgId', this.orgId)
      if (id) {
        this.$set(this.editParams, 'phoneId', id)
      } else {
        this.$set(this.editParams, 'phoneId', undefined)
      }
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
