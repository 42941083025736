<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="createOrgFromContact"
    ref="createOrgFromContact"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="createOrgFromContactOnHidden"
  >
    <div slot="modal-title">
      {{ title | translate }}
    </div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-checkbox-group
          :validator="$v.form.organization_types"
          :external-errors="errors['organization_types']"
          class-form="col-12 mb-3"
          labelColsSm="0"
          labelColsLg="0"
          :options="orgTypeOptions"
          :value="form.organization_types"
          @change="setOrganizationTypes"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.ssn"
          :external-errors="errors['ssn']"
          :label-form="'SSN' | translate"
          class-form="col-lg-12 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="form.ssn"
          @change="$set(form, 'ssn', $event)"
        />
      </b-form-row>
    </b-form>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="cancelOperation()">
        {{ 'Close' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndCreateOrganization()"
        :disabled="$v.form.$invalid || form.organization_types.length === 0 "
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CONTACT_TYPES } from '../../../contact/store'
import { ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

export default {
  name: 'CreateOrganizationFromContactModal',
  props: ['contact'],
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      form: {
        organization_types: []
      },
      errors: {}
    }
  },
  components: {
  },
  created () {
    this.$set(this, 'form', this.contact)
    this.$set(this.form, 'organization_types', [])
    this.getOrgtypechoices()
  },
  mounted () {
    this.opencreateOrgFromContact()
  },
  computed: {
    title () {
      return this.$t('Create private organization')
    },
    ...mapGetters({
      orgTypeChoices: CONTACT_TYPES.GENERIC.organization.contact.LIST.GETTERS.orgTypeChoices
    }),
    orgTypeOptions: function () {
      const res = []
      if (this.orgTypeChoices && Array.isArray(this.orgTypeChoices)) {
        this.orgTypeChoices.forEach(element => {
          res.push({ text: element.name, value: element.id })
        })
      }
      return res
    }
  },
  methods: {
    init () {},
    ...mapActions({
      getOrgtypechoices: CONTACT_TYPES.GENERIC.organization.contact.LIST.ACTIONS.getOrgtypechoices
    }),
    opencreateOrgFromContact () {
      this.$refs.createOrgFromContact.show()
    },
    createOrgFromContactOnHidden (evt) {
      this.$emit('close-modal')
    },
    cancelOperation (evt) {
      this.$emit('close-modal')
    },
    closeAndCreateOrganization (evt) {
      const formData = Object.assign({}, this.form)
      this.$emit('close-modal-and-create', formData)
    },
    setOrganizationTypes (evt) {
      if (evt && this.orgTypeChoices) {
        const newValues = evt
        let isExtra = false
        const extraElement = this.orgTypeChoices.find(el => el.code === ORGANIZATION_CUSTOMER_LEAD_CODE)
        const difference = evt.filter(x => !this.form.organization_types.includes(x))

        if (difference.length > 0 && !isExtra) {
          if (difference.includes(extraElement.id)) {
            this.$set(this.form, 'organization_types', [extraElement.id])
            isExtra = true
          } else {
            if (newValues.includes(extraElement.id)) {
              const ax = newValues.indexOf(extraElement.id)
              newValues.splice(ax, 1)
            }
            this.$set(this.form, 'organization_types', newValues)
          }
        }
        if (difference.length === 0 && !isExtra) {
          this.$set(this.form, 'organization_types', newValues)
        }
      }
    }
  },
  validations: {
    form: {
      organization_types: { },
      ssn: { required }
    }
  }
}
</script>
