<script>
export default {
  name: 'OrganizationTypeMixin',
  props: {
    orgInvTypeList: { required: true }
  },
  computed: {
    organizationInvoiceTypeOptions () {
      if (this.orgInvTypeList && this.orgInvTypeList.length > 0) {
        return this.prepareChoiceListForSelect(this.orgInvTypeList, 0, 1)
      }
      return []
    }
  },
  methods: {
    selectOrganizationType (item) {
      this.$set(this.form, 'typology', item.value)
      this.$set(this.form, 'typology_name', item.name)
    },
    clearOrganizationType () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'typology_name', null)
    }
  }
}
</script>
