<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Bank name' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.iban"
            :external-errors="errors['iban']"
            :label-form="'IBAN' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.iban"
            :disabled="!formEditable"
            @change="setIbanCode($event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.abi"
            :external-errors="errors['abi']"
            :label-form="'ABI' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.abi"
            :disabled="!formEditable"
            @change="$set(form, 'abi', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.cab"
            :external-errors="errors['cab']"
            :label-form="'CAB' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cab"
            :disabled="!formEditable"
            @change="$set(form, 'cab', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.cin"
            :external-errors="errors['cin']"
            :label-form="'CIN' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cin"
            :disabled="!formEditable"
            @change="$set(form, 'cin', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.int_code"
            :external-errors="errors['int_code']"
            :label-form="'Int. Code' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.int_code"
            :disabled="!formEditable"
            @change="$set(form, 'int_code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.cc"
            :external-errors="errors['cc']"
            :label-form="'CC' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            :value="form.cc"
            :disabled="!formEditable"
            @change="$set(form, 'cc', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_default"
            :external-errors="errors['is_default']"
            :label-form="'Default bank' | translate"
            label-form-class="mt-2"
            class="col-lg-6 mb-2 mt-2"
            :value="form.is_default"
            :disabled="!formEditable"
            @change="$set(form, 'is_default', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as BANK_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'BankEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'BANK',
      rolePerm: 'bank_bank_retrieve',
      editRolePerm: 'bank_bank_update',
      bankId: undefined,
      orgId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...BANK_TYPES.GENERIC.bank.bank.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...BANK_TYPES.GENERIC.bank.bank.DETAIL.ACTIONS
    }),
    initFields () {
      this.$set(this, 'bankId', this.id)
      this.$set(this, 'orgId', this.editParams.orgId)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.bankId) {
        formData.organization = this.orgId
      }
      return formData
    },
    setIbanCode (evt) {
      this.$set(this.form, 'iban', evt)
      if (evt) {
        this.$set(this.form, 'int_code', evt.substring(0, 2))
        this.$set(this.form, 'cin', evt.substring(4, 5))
        this.$set(this.form, 'abi', evt.substring(5, 10))
        this.$set(this.form, 'cab', evt.substring(10, 15))
        this.$set(this.form, 'cc', evt.substring(15))
      } else {
        this.$set(this.form, 'abi', undefined)
        this.$set(this.form, 'cab', undefined)
        this.$set(this.form, 'int_code', undefined)
        this.$set(this.form, 'cin', undefined)
        this.$set(this.form, 'cc', undefined)
      }
    }
  },
  validations () {
    const validator = {
      form: {
        name: { required },
        iban: {
          required,
          maxLength: maxLength(34)
        },
        abi: {
          required,
          maxLength: maxLength(5)
        },
        cab: {
          required,
          maxLength: maxLength(5)
        },
        int_code: {
          required,
          maxLength: maxLength(2)
        },
        cin: {
          required,
          maxLength: maxLength(1)
        },
        cc: {
          required,
          maxLength: maxLength(12)
        },
        is_default: { }
      }
    }
    return validator
  }
}
</script>
