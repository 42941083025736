<template>
 <panel :title="title" noButton="true" v-has-perms="rolePerm">
  <div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.typology"
          :external-errors="errors['typology']"
          :label-form="'Phone Type' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          label-search="name"
          :customize-option="true"
          :value="form.typology ? { value: form.typology, name: form.typology_name } : null"
          :options="emailTypologyOptions"
          @select="selectEmailTypology"
          @clear="clearEmailTypology"
        />
        <form-thux-horizontal-input
          :validator="$v.form.phone"
          :external-errors="errors['phone']"
          :label-form="'Phone' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.phone"
          :disabled="!formEditable"
          @change="$set(form, 'phone', $event)"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.form.is_primary"
          :external-errors="errors['is_primary']"
          :label-form="'Primary Phone' | translate"
          class="col-lg-12 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.is_primary"
          :disabled="!formEditable"
          @change="$set(form, 'is_primary', $event)"
        />
          <form-thux-horizontal-text-area
          :validator="$v.form.note"
          :label-form="'Note' | translate"
          class-form="col-12 my-2"
          labelColsSm="4"
          labelColsLg="3"
          rows="3"
          max-rows="6"
          :value="form.note"
          :disabled="!formEditable"
          @change="$set(form, 'note', $event)"
        />
      </b-form-row>
    </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_PHONE_TYPES } from './store'
import PopulateSelectMixin from '@/components/PopulateSelectMixin'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import PhoneTypologyMixin from './mixins/PhoneTypologyMixin'

export default {
  name: 'OrganizationPhoneEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, PopulateSelectMixin, PhoneTypologyMixin],
  data () {
    return {
      pageName: 'organization phone',
      rolePerm: 'organization_organizationphone_retrieve',
      editRolePerm: 'organization_organizationphone_update',
      orgId: undefined,
      phoneId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_PHONE_TYPES.GENERIC.organization.organizationphone.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...ORG_PHONE_TYPES.GENERIC.organization.organizationphone.DETAIL.ACTIONS
    }),
    initFields () {
      this.$set(this, 'phoneId', this.id)
      this.$set(this, 'orgId', this.editParams.orgId)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.phoneId) {
        formData.organization = this.orgId
      }
      return formData
    }
  },
  validations () {
    const vals = {
      form: {
        typology: {
          required
        },
        phone: {
          required
        },
        is_primary: {},
        note: {}
      }
    }
    return vals
  }
}
</script>
