<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'
import { vue } from '../../../../../main'

export default {
  name: 'AtecoCodeMixin',
  created () {
    this.setAtecolist({})
  },
  computed: {
    ...mapGetters({
      atecoList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.atecoList
    }),
    atecoCodeOptions () {
      if (this.atecoList) {
        return this.prepareListForMultiSelect(this.atecoList.results, 'id', ['code', 'name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setAtecolist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setAtecolist
    }),
    ...mapActions({
      getAtecolist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getAtecolist
    }),
    onSearchAtecoCode (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.ateco_code__icontains = search
        filters.order_by = '-status.-date_create'
        this.getAtecolist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setAtecolist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    getAtecoCodes (formData) {
      const values = []
      if (formData.ateco_codes) {
        this.atecoCodeOptions.forEach(element => {
          if (formData.ateco_codes.includes(element.id)) {
            const name = element.code ? `${element.code} ${element.name}` : `${element.name}`
            values.push({
              id: element.id,
              name: name.substring(0, 35) + '...',
              status: element.status
            })
          }
        })
        return values
      }
    },
    setAtecoCodes (formData) {
      if (this.row.ateco_codes) {
        const list = []
        this.row.ateco_codes.forEach((row) => list.push(row.id))
        this.$set(formData, 'ateco_codes', new Set(list))
      } else {
        this.$set(formData, 'ateco_codes', [])
      }
    },
    removeAtecoCode (item) {
      const orgCodes = this.row.ateco_codes.filter((ateco) => {
        return ateco.id !== item.id
      })
      this.$set(this.row, 'ateco_codes', orgCodes)
      if (this.row.ateco_codes.length === 0) this.$set(this.row, 'ateco_codes', null)
    },
    selectAtecoCode (item) {
      if (!this.row.ateco_codes) {
        this.$set(this.row, 'ateco_codes', [])
      }
      item.name = item.name.substring(0, 35) + '...'
      this.row.ateco_codes.push(item)
    }
  }
}
</script>
