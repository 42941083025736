<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'

export default {
  name: 'SoleShareholderMixin',
  created () {
    this.getSoleshareholderchoices()
  },
  computed: {
    ...mapGetters({
      soleShareholderList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.soleShareholderChoices
    }),
    organizationSoleShareholderOptions () {
      const enabledList = []
      if (this.soleShareholderList && Array.isArray(this.soleShareholderList)) {
        this.soleShareholderList.forEach(function (element) {
          enabledList.push({ value: element[0], name: element[1] })
        })
      }
      return enabledList
    }
  },
  methods: {
    ...mapMutations({
      setSoleshareholderchoices: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setSoleshareholderchoices
    }),
    ...mapActions({
      getSoleshareholderchoices: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getSoleshareholderchoices
    }),
    selectOrganizationSoleShareholder (item) {
      this.$set(this.row, 'sole_shareholder', item.value)
      this.$set(this.row, 'get_sole_shareholder_display', item.name)
    },
    clearOrganizationSoleShareholder () {
      this.$set(this.row, 'sole_shareholder', null)
      this.$set(this.row, 'get_sole_shareholder_display', null)
    }
  }
}
</script>
