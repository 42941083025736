<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'

export default {
  name: 'OrganizationTaxRegimeMixin',
  created () {
    this.getTaxregimelist()
  },
  computed: {
    ...mapGetters({
      taxRegimeList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.taxRegimeList
    }),
    organizationInvoiceTaxRegimeOptions () {
      const enabledList = []
      if (this.taxRegimeList && Array.isArray(this.taxRegimeList)) {
        this.taxRegimeList.forEach(function (element) {
          enabledList.push({ value: element[0], name: element[1] })
        })
      }
      return enabledList
    }
  },
  methods: {
    ...mapMutations({
      setTaxregimelist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setTaxregimelist
    }),
    ...mapActions({
      getTaxregimelist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getTaxregimelist
    }),
    selectOrganizationTaxRegime (item) {
      this.$set(this.row, 'tax_regime', item.value)
      this.$set(this.row, 'get_tax_regime_display', item.name)
    },
    clearOrganizationTaxRegime () {
      this.$set(this.row, 'tax_regime', null)
      this.$set(this.row, 'get_tax_regime_display', null)
    }
  }
}
</script>
