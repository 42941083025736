<template>
  <div>
    <contact-create-form
      :router-name-list="routeNameList"
      :errors="errors"
      :org-type="orgType"
      :org-id="orgId"
      @submit="onCreate"
      v-on:close-contact-create="onCancel"
    />
    <duplicated-contact-modal
      v-if="showDuplicatedForm"
      v-on:close-modal="closeModal"
      :org-type="orgType"
      v-on:close-modal-and-change="goToDetailPage"
      v-on:close-modal-and-add-contact="addContactToOrganization"
      :cntFilters="cntFilters"
    ></duplicated-contact-modal>
  </div>
</template>

<script>
import PopulateSelectMixin from '@/components/PopulateSelectMixin'
import ContactCreateForm from '../ContactCreateForm'
import DuplicatedContactModal from '../contact-modal/DuplicatedContactModal'
import { ROUTES as SUBJECT_CONTACT_ROUTES } from '../../../contact/contact/subject-contact/router'
import { ROUTES as CUSTOMER_CONTACT_ROUTES } from '../../../contact/contact/customer-contact/router'
import { ROUTES as LEAD_CONTACT_ROUTES } from '../../../contact/contact/lead-contact/router'
import { ROUTES as SUPPLIER_CONTACT_ROUTES } from '../../../contact/contact/supplier-contact/router'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '../../../../../const'

export default {
  name: 'ContactCreateMixin',
  mixins: [PopulateSelectMixin],
  components: {
    ContactCreateForm,
    DuplicatedContactModal
  },
  data () {
    return {
      routeNameList: '',
      routeNameDetail: '',
      title: '',
      orgType: '',
      orgId: '',
      form: {},
      showDuplicatedForm: false,
      cntFilters: undefined
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.cleanErrors()
    },
    onCreate (evt) {
      const formData = evt.data
      if (this.orgId) {
        formData.organizations = [{
          organization: this.orgId
        }]
      }
      this.createContactWithOrganizations(formData)
        .then(() => {
          this.$emit('close-form')
          if (this.routeNameDetail) {
            setTimeout(() => {
              this.$router.push({ name: this.routeNameDetail, params: { cntId: this.detail.id } })
            }, 500)
          }
        })
        .catch((error) => {
          this.cntFilters = {
            last_name: formData.last_name,
            first_name: formData.first_name
          }
          if (formData.ssn) {
            this.cntFilters.ssn = formData.ssn
          }
          if (formData.vat_number) {
            this.cntFilters.vat_number = formData.vat_number
          }
          if ('duplicated_contact' in error.response.data) {
            this.showDuplicatedForm = true
          }
        })
    },
    closeModal () {
      this.cntFilters = false
      this.showDuplicatedForm = false
    },
    onCancel () {
      this.$emit('close-form')
    },
    addContactToOrganization (evt) {
      this.createOrganizationContact({ organization: this.orgId, contact: evt.cntId }).then(
        () => {
          this.getContactList()
          this.$emit('close-form')
        }
      )
      this.closeModal()
    },
    goToDetailPage (evt) {
      if (evt.orgTypes.length === 0) {
        this.$router.push({ name: SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_CONTACT_ROUTES.LEAD_CONTACT_DETAIL, params: { cntId: evt.cntId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      }
      this.closeModal()
    }
  }
}
</script>
