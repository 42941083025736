<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from '../store'
import { vue } from '../../../../../main'

export default {
  name: 'EqualOrganizationMixin',
  created () {
    this.setEqualorganizationlist({})
  },
  computed: {
    ...mapGetters({
      equalOrganizationList: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.GETTERS.equalOrganizationList
    }),
    equalOrganizationOptions () {
      const options = []
      if (this.equalOrganizationList && this.equalOrganizationList.results && this.equalOrganizationList.results.length > 0) {
        this.equalOrganizationList.results.forEach((equalOrganization) => {
          const option = {
            value: equalOrganization.id,
            name: `${equalOrganization.code} - ${equalOrganization.name}`,
            status: equalOrganization.status
          }
          if (equalOrganization.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setEqualorganizationlist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS.setEqualorganizationlist
    }),
    ...mapActions({
      getEqualorganizationlist: ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.ACTIONS.getEqualorganizationlist
    }),
    onSearchEqualorganization (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.equal_organization__icontains = search
        filters.order_by = '-status.-date_create'
        this.getEqualorganizationlist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setEqualorganizationlist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectEqualorganization (item) {
      this.$set(this.row, 'equal_organization', item.value)
      this.$set(this.row, 'equal_organization_name', item.name)
    },
    clearEqualorganization () {
      this.$set(this.row, 'equal_organization', null)
      this.$set(this.row, 'equal_organization_name', null)
    }
  }
}
</script>
