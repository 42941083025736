<template>
  <b-form>
    <b-form-row v-if="orgType !== 'own'">
      <form-thux-horizontal-checkbox-group
        :validator="$v.form.organization_types"
        :external-errors="errors['organization_types']"
        class-form="col-12 mb-3"
        labelColsSm="0"
        labelColsLg="0"
        :options="orgTypeOptions"
        :value="form.organization_types"
        :disabled="!formEditable"
        @change="setOrganizationTypes"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.name"
        :external-errors="errors['name']"
        :label-form="'Business Name' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.name"
        :disabled="!formEditable"
        @change="$set(form, 'name', $event)"
      />
      <form-thux-horizontal-select
        :validator="$v.form.organization_form"
        :external-errors="errors['organization_form']"
        :label-form="'Organization form' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        :disabled="!formEditable"
        label-search="name"
        :value="form.organization_form ? { id: form.organization_form, name: form.organization_form_name } : null"
        :placeholder="$t('Select an option')"
        :options="organizationFormListOptions"
        @select="selectOrganizationForm"
        @clear="clearOrganizationForm"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-multiselect
        :validator="$v.form.organization_groups"
        label-form="Category"
        label-form-class="mb-2"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        :external-errors="errors['organization_groups']"
        label-search="name"
        :disabled="!formEditable"
        :value="form.organization_groups"
        :options="organizationGroupListOptions"
        :placeholder="
          $t('Select a {instance}', { instance: $t('category') })
        "
        @search="onSearchGroup"
        @remove="removeOrganizationGroup"
        @select="selectOrganizationGroup"
        @clear="$set(form, 'organization_groups', null)"
      />
      <form-thux-horizontal-select
        :validator="$v.form.typology"
        :external-errors="errors['typology']"
        :label-form="'Organization type' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        :disabled="!formEditable"
        :label-search="'name'"
        :value="form.typology ? { value: form.typology, name: form.typology_name } : null"
        :options="organizationInvoiceTypeOptions"
        :placeholder="$t('Select an organization type')"
        @select="selectOrganizationType"
        @clear="clearOrganizationType"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-select
        :validator="$v.form.organization_category"
        :external-errors="errors['organization_category']"
        :label-form="'Organization Employee Category' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        :disabled="!formEditable"
        :label-search="'name'"
        :value="form.organization_category ? { value: form.organization_category, name: form.organization_category_name } : null"
        :options="organizationEmployeeCategoryOptions"
        :placeholder="$t('Select an organization employee category')"
        @select="selectOrganizationEmployeeCategory"
        @clear="clearOrganizationEmployeeCategory"
      />
      <form-thux-horizontal-input v-if="form.typology !== 'PA'"
        :validator="$v.form.sdi_code"
        :external-errors="errors['sdi_code']"
        :label-form="'SDI' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.sdi_code"
        :disabled="!formEditable"
        @change="$set(form, 'sdi_code', $event)"
      />
      <form-thux-horizontal-input v-if="form.typology === 'PA'"
        :validator="$v.form.sdi_code"
        :external-errors="errors['sdi_code']"
        :label-form="'Unique Office Code' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.sdi_code"
        :disabled="!formEditable"
        @change="$set(form, 'sdi_code', $event)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.ssn"
        :external-errors="errors['ssn']"
        :label-form="'SSN' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.ssn"
        :disabled="!formEditable"
        @change="$set(form, 'ssn', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.vat_number"
        :external-errors="errors['vat_number']"
        :label-form="'VAT number' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.vat_number"
        :disabled="!formEditable"
        @change="$set(form, 'vat_number', $event)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.pec"
        :external-errors="errors['pec']"
        :label-form="'PEC' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.pec"
        :disabled="!formEditable"
        @change="$set(form, 'pec', $event)"
      />
      <form-thux-horizontal-file
        :validator="$v.form.logo"
        :external-errors="errors['logo']"
        :label-form="'Logo' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        :value="form.logo"
        :disabled="!formEditable"
        :placeholder="logoPlaceholder ? logoPlaceholder : $t('Choose an image or drop it here...')"
        :drop-placeholder="$t('Drop image here...')"
        @input="$set(form, 'logo', $event)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-text-area
        :validator="$v.form.note"
        :external-errors="errors['note']"
        :label-form="'Note' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.note"
        rows="3"
        max-rows="6"
        :disabled="!formEditable"
        @change="$set(form, 'note', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.internal_code"
        :external-errors="errors['internal_code']"
        :label-form="'Internal code' | translate"
        class-form="col-lg-6 mb-2"
        labelColsSm="3"
        labelColsLg="3"
        type="text"
        :value="form.internal_code"
        :disabled="!formEditable"
        @change="$set(form, 'internal_code', $event)"
      />
    </b-form-row>
    <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        v-if="formEditable"
        class="mr-3"
        variant="default"
        @click.prevent="cancel()"
      >
        {{ 'Cancel' | translate }}
      </b-button>
      <b-button
        variant="primary"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        v-has-perms="editRolePerm"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { VALID_ORGANIZATION_TYPE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_CUSTOMER_CODE } from '@/const'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'

import OrganizationGroupMixin from './mixins/OrganizationGroupMixin'
import OrganizationFormMixin from './mixins/OrganizationFormMixin'
import OrganizationTypeMixin from './mixins/OrganizationTypeMixin'
import OrganizationCategoryEmployeeMixin from './mixins/OrganizationCategoryEmployeeMixin'

export default {
  name: 'OrganizationForm',
  mixins: [
    ThuxDetailPanelMixin,
    OrganizationFormMixin,
    OrganizationGroupMixin,
    OrganizationTypeMixin,
    OrganizationCategoryEmployeeMixin
  ],
  props: {
    logoPlaceholder: {},
    errors: { required: true },
    orgType: { required: true },
    routerNameList: { required: true },
    isExtra: {},
    orgTypeList: {},
    formInitial: {},
    operation: {},
    editRolePerm: undefined
  },
  watch: {
    formInitial () {
      this.init()
    }
  },
  data () {
    return {
      form: {
        organization_groups: [],
        organization_types: []
      },
      VALID_ORGANIZATION_TYPE
    }
  },
  computed: {
    orgTypeOptions: function () {
      const res = []
      if (this.orgTypeList && Array.isArray(this.orgTypeList)) {
        this.orgTypeList.forEach(element => {
          if (element.code === this.orgType) {
            res.push({ text: element.name, value: element.id, disabled: true })
            if (!this.form.organization_types || this.form.organization_types.length === 0) {
              this.$set(this.form, 'organization_types', [element.id])
            }
          } else {
            if (this.operation === 'update') {
              res.push({ text: element.name, value: element.id })
            } else {
              if (!this.isExtra && element.code !== ORGANIZATION_CUSTOMER_LEAD_CODE) {
                res.push({ text: element.name, value: element.id })
              }
            }
          }
        })
      }
      return res
    }
  },
  methods: {
    init () {
      if (this.id && this.formInitial) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const orgData = Object.assign({}, this.formInitial)
      if (this.formInitial.organization_form) {
        orgData.organization_form_name = this.formInitial.organization_form.name
        orgData.organization_form = this.formInitial.organization_form.id
      }
      orgData.typology_name = this.formInitial.get_typology_display
      orgData.typology = this.formInitial.typology
      this.$set(this, 'form', orgData)
    },
    cancel () {
      if (!this.id) {
        this.$router.push({ name: this.routerNameList })
      } else {
        this.setForm()
        this.makeFormReadonly()
      }
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        let formData = Object.assign({}, this.form)
        formData = this.setOrganizationGroup(formData)
        this.$emit('submit', { data: formData })
      }
    },
    setOrganizationTypes (evt) {
      if (evt && this.orgTypeList) {
        const newValues = evt
        let isExtra = false
        const extraElement = this.orgTypeList.find(el => el.code === ORGANIZATION_CUSTOMER_LEAD_CODE)

        const customerElement = this.orgTypeList.find(el => el.code === ORGANIZATION_CUSTOMER_CODE)
        const difference = evt.filter(x => !this.form.organization_types.includes(x))

        if (difference.length > 0 && !isExtra) {
          if (difference.includes(extraElement.id)) {
            this.$set(this.form, 'organization_types', [extraElement.id])
            isExtra = true
          } else {
            if (newValues.includes(extraElement.id)) {
              const ax = newValues.indexOf(extraElement.id)
              newValues.splice(ax, 1)
            }
            this.$set(this.form, 'organization_types', newValues)
          }
        }
        if (difference.length === 0 && !isExtra) {
          this.$set(this.form, 'organization_types', newValues)
        }
        console.log(customerElement)
      }
    }
  },
  validations () {
    const vals = {
      form: {
        organization_types: { },
        name: {
          required
        },
        organization_form: { },
        organization_groups: { },
        organization_category: { },
        ssn: { },
        vat_number: { },
        typology: { },
        sdi_code: { },
        pec: { email },
        logo: {},
        internal_code: { }
      }
    }
    if (this.form.typology === 'CM') {
      vals.form.sdi_code = {
        maxLength: maxLength(7)
      }
      vals.form.vat_number = { required }
      vals.form.ssn = { }
    }
    if (this.form.typology === 'CN') {
      vals.form.sdi_code = {
        maxLength: maxLength(7)
      }
      vals.form.vat_number = {}
      vals.form.ssn = { required }
    }
    if (this.form.typology === 'PA') {
      vals.form.sdi_code = {
        maxLength: maxLength(6)
      }
      vals.form.vat_number = { required }
      vals.form.ssn = { }
    }
    if (this.form.typology === 'IC') {
      vals.form.pec = { }
      vals.form.sdi_code = { }
      vals.form.ssn = { }
      vals.form.vat_number = { required }
    }
    if (this.form.typology === 'EC') {
      vals.form.pec = { }
      vals.form.sdi_code = { }
      vals.form.ssn = { }
      vals.form.vat_number = { }
    }
    if (this.form.typology === 'PR') {
      vals.form.ssn = { required }
      vals.form.vat_number = { }
    }
    return vals
  }
}
</script>
