<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as OWNER_ORG_TYPES } from '../../organization/organization/owner-organization/store'

export default {
  name: 'StaffTypeMixin',
  created () {
    if (!this.staffTypes || (this.staffTypes && this.staffTypes.length === 0)) {
      this.getStafftypes()
    }
  },
  computed: {
    ...mapGetters({
      staffTypes: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS.staffTypes
    }),
    staffTypeListOptions () {
      return this.prepareListForSelect(this.staffTypes, 'id', ['name'])
    }
  },
  methods: {
    ...mapActions({
      getStafftypes: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS.getStafftypes
    }),
    selectStaffType (item) {
      this.$set(this.form, 'staff_type', item.value)
      this.$set(this.form, 'staff_type_name', item.name)
    },
    clearStaffType () {
      this.$set(this.form, 'staff_type', null)
      this.$set(this.form, 'staff_type_name', null)
    }
  }
}
</script>
