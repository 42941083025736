<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import OrganizationEmailEdit from './OrganizationEmailEdit.vue'
import { TYPES as ORG_EMAIL_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'
import OrganizationEmailTable from './OrganizationEmailTable'

export default {
  name: 'OrganizationEmailList',
  props: ['orgId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationEmailTable,
    'component-edit': OrganizationEmailEdit
  },
  data () {
    return {
      title: this.$t('Organization Emails'),
      rolePerm: ['organization_organizationemail_list'],
      actionEnablePermission: ['organization_organizationemail_enable'],
      actionDisablePermission: ['organization_organizationemail_disable'],
      showHeader: false,
      showCommonFilters: false,

      showEmailEdit: false,
      emailId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationemail_create')
    }
  },
  methods: {
    ...mapActions({
      ...ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.ACTIONS
    }),
    ...mapMutations({
      setSelectedList: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_EMAIL_TYPES.GENERIC.organization.organizationemail.LIST.MUTATIONS.setSelectAll
    }),
    getComponentList () {
      this.setFilters({ filter__organization__id: this.orgId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'orgId', this.orgId)
      if (id) {
        this.$set(this.editParams, 'emailId', id)
      } else {
        this.$set(this.editParams, 'emailId', undefined)
      }
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
