<template>
 <panel :title="title" noButton="true">
  <div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.last_name"
          :external-errors="errors['last_name']"
          :label-form="'Last name' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.last_name"
          @change="$set(form, 'last_name', $event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.first_name"
          :external-errors="errors['first_name']"
          :label-form="'First name' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.first_name"
          @change="$set(form, 'first_name', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.ssn"
          :external-errors="errors['ssn']"
          :label-form="'SSN' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.ssn"
          @change="$set(form, 'ssn', $event)"
        />
        <form-thux-horizontal-file
          :validator="$v.form.photo"
          :external-errors="errors['photo']"
          :label-form="'Photo' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.photo"
          :placeholder="$t('Choose an image or drop it here...')"
          :drop-placeholder="$t('Drop image here...')"
          @input="$set(form, 'photo', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.birth_date"
          :external-errors="errors['birth_date']"
          :label-form="'Birth date' | translate"
          labelColsSm="4"
          labelColsLg="3"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="birthDate"
          :placeholder="$t('Enter the date')"
          @change="birthDateChange($event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.birth_place"
          :external-errors="errors['birth_place']"
          :label-form="'Birth place' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.birth_place"
          @change="$set(form, 'birth_place', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-multiselect
          v-if="!orgId && (orgType === 'sup' || orgType === 'cus')"
          :is-required="orgType === 'sup' || orgType === 'cus'"
          :validator="$v.form.organizations"
          label-form="Organizations"
          label-form-class="mb-2"
          class-form="col-lg-6 mb-2 mt-2"
          :external-errors="errors['organizations']"
          label-search="name"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.organizations"
          :options="organizationListOptions"
          :placeholder="$t('Search an organization')"
          @search="onSearchOrganization"
          @remove="removeOrganization"
          @select="selectOrganization"
          @clear="$set(form, 'organizations', null)"
        />
      </b-form-row>
    </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="cancel()">
          {{ 'Cancel' | translate }}
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="onSubmit()"
          :disabled="$v.form.$invalid">
          {{ 'Save' | translate }}
        </b-button>
      </div>
  </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import FormThuxHorizontalDatetimePicker from '@/components/form-thux-horizontal-components/FormThuxHorizontalDatetimePicker'
import FormThuxHorizontalInput from '@/components/form-thux-horizontal-components/FormThuxHorizontalInput'
import FormThuxHorizontalMultiselect from '@/components/form-thux-horizontal-components/FormThuxHorizontalMultiselect'
import OrganizationMixin from '../organization/mixins/OrganizationMixin'
import FormThuxHorizontalFileThux from '../../../../components/form-thux-horizontal-components/FormThuxHorizontalFileThux'

export default {
  name: 'ContactCreateForm',
  props: ['orgId', 'orgType', 'errors', 'cleanErrors'],
  mixins: [OrganizationMixin],
  data () {
    return {
      form: {},
      birthDate: undefined
    }
  },
  computed: {
    ...mapGetters({ }),
    title: function () {
      return this.$t('Contact Data')
    }
  },
  components: {
    'form-thux-horizontal-datetime': FormThuxHorizontalDatetimePicker,
    'form-thux-horizontal-input': FormThuxHorizontalInput,
    'form-thux-horizontal-multiselect': FormThuxHorizontalMultiselect,
    'form-thux-horizontal-file': FormThuxHorizontalFileThux
  },
  methods: {
    ...mapActions({ }),
    birthDateChange (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'birth_date', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'birth_date', undefined)
      }
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      if (this.orgId) {
        formData.organization = this.orgId
      } else {
        formData.organizations = this.setOrganizations(formData)
      }
      this.$emit('submit', { data: formData })
    },
    cancel () {
      this.$emit('close-contact-create')
    }
  },
  validations: {
    form: {
      last_name: {
        required
      },
      first_name: {
        required
      },
      ssn: {},
      photo: {},
      birth_date: {},
      birth_place: {},
      organizations: {
        required: (value, vm) => {
          if (!vm.__ob__.dep.subs[0].vm.orgId && (vm.__ob__.dep.subs[0].vm === 'sup' || vm.__ob__.dep.subs[0].vm === 'cus')) {
            return !!value
          }
          return true
        }
      }
    }
  }
}
</script>
