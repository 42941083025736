<template>
  <panel :title="$t('CCIAA Data')" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.row.date_start_activity"
            :external-errors="errors['date_start_activity']"
            :label-form="'Date start activity' | translate"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            class-form="col-lg-6 mb-2 mt-2"
            type="date"
            :value="dateStartActivity"
            :placeholder="$t('Enter the date')"
            @change="dateStartChange($event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-multiselect
            :validator="$v.row.ateco_codes"
            label-form="ATECO Codes"
            label-form-class="mb-2"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :external-errors="errors['ateco_codes']"
            label-search="name"
            :disabled="!formEditable"
            :value="row.ateco_codes"
            :options="atecoCodeOptions"
            :placeholder="
              $t('Search or select a {instance}', { instance: $t('ATECO code') })
            "
            @search="onSearchAtecoCode"
            @remove="removeAtecoCode"
            @select="selectAtecoCode"
            @clear="$set(row, 'ateco_codes', null)"
          />
          <form-thux-horizontal-input
            :validator="$v.row.activity_desc"
            :external-errors="errors['activity_desc']"
            :label-form="'Activity description' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.activity_desc"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'activity_desc', $event)">
          </form-thux-horizontal-input>
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.row.rea_code"
            :external-errors="errors['rea_code']"
            :label-form="'REA registration' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.rea_code"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'rea_code', $event)">
          </form-thux-horizontal-input>
          <form-thux-horizontal-autocomplete
            :validator="$v.row.ccnl"
            :external-errors="errors['ccnl']"
            :label-form="'CCNL' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'name'"
            labelColsSm="4"
            labelColsLg="3"
            :value="row.ccnl ? { value: row.ccnl, name: row.ccnl_name } : null"
            :options="ccnlOptions"
            :placeholder="$t('Select a ccnl')"
            :placeholder-field="row.ccnl_name"
            @search="onSearchCcnl"
            @select="selectCcnl"
            @clear="clearCcnl"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.row.inail_code"
            :external-errors="errors['inail_code']"
            :label-form="'INAIL Code' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.inail_code"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'inail_code', $event)">
          </form-thux-horizontal-input>
          <form-thux-horizontal-input
            :validator="$v.row.inps_code"
            :external-errors="errors['inps_code']"
            :label-form="'INPS registration' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.inps_code"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'inps_code', $event)">
          </form-thux-horizontal-input>
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.row.rct_insurance"
            :external-errors="errors['rct_insurance']"
            :label-form="'RCT insurance' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.rct_insurance"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'rct_insurance', $event)">
          </form-thux-horizontal-input>
          <form-thux-horizontal-input
            :validator="$v.row.rco_insurance"
            :external-errors="errors['rco_insurance']"
            :label-form="'RCO insurance' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.rco_insurance"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'rco_insurance', $event)">
          </form-thux-horizontal-input>
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.row.equal_organization"
            :external-errors="errors['equal_organization']"
            :label-form="'Equal organization' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'name'"
            labelColsSm="4"
            labelColsLg="3"
            :value="row.equal_organization ? { value: row.equal_organization, name: row.equal_organization_name } : null"
            :options="equalOrganizationOptions"
            :placeholder="$t('Select an equal organization')"
            :placeholder-field="row.equal_organization_name"
            @search="onSearchEqualorganization"
            @select="selectEqualorganization"
            @clear="clearEqualorganization"
          />
          <form-thux-horizontal-input
            :validator="$v.row.craftsmen_register_code"
            :external-errors="errors['craftsmen_register_code']"
            :label-form="'Craftsmen register' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.craftsmen_register_code"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'craftsmen_register_code', $event)">
          </form-thux-horizontal-input>
          <!-- <form-thux-horizontal-autocomplete
            :validator="$v.row.professional_register"
            :external-errors="errors['professional_register']"
            :label-form="'Professional register' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'name'"
            labelColsSm="4"
            labelColsLg="3"
            :value="row.professional_register ? { value: row.professional_register, name: row.professional_register_name } : null"
            :options="professionalRegisterOptions"
            :placeholder="$t('Select a professional register')"
            :placeholder-field="row.professional_register_name"
            @search="onSearchProfessionalRegister"
            @select="selectProfessionalRegister"
            @clear="clearProfessionalRegister"
          /> -->
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.row.construction_fund"
            :external-errors="errors['construction_fund']"
            :label-form="'Construction fund' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.construction_fund"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'construction_fund', $event)">
          </form-thux-horizontal-input>
          <form-thux-horizontal-input
            :validator="$v.row.waste_manager_register"
            :external-errors="errors['waste_manager_register']"
            :label-form="'Waste manager register' | translate"
            type="text"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.waste_manager_register"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'waste_manager_register', $event)">
          </form-thux-horizontal-input>
        </b-form-row>
        <b-form-row v-if="orgType === 'own'">
          <form-thux-horizontal-select
            :validator="$v.row.tax_regime"
            :external-errors="errors['tax_regime']"
            :label-form="'Tax regime' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="row.tax_regime ? { value: row.tax_regime, name: row.get_tax_regime_display } : null"
            :options="organizationInvoiceTaxRegimeOptions"
            :placeholder="$t('Select a tax regime')"
            @select="selectOrganizationTaxRegime"
            @clear="clearOrganizationTaxRegime"
          />
          <form-thux-horizontal-input
            :validator="$v.row.share_capital"
            :external-errors="errors['share_capital']"
            :label-form="'Share capital' | translate"
            type="number"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :value="row.share_capital"
            class-form="col-lg-6 mb-2 mt-2"
            @change="$set(row, 'share_capital', $event)">
          </form-thux-horizontal-input>
        </b-form-row>
        <b-form-row v-if="orgType === 'own'">
          <form-thux-horizontal-select
            :validator="$v.row.liquidation_status"
            :external-errors="errors['liquidation_status']"
            :label-form="'Liquidation status' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="row.liquidation_status ? { value: row.liquidation_status, name: row.get_liquidation_status_display } : null"
            :options="organizationLiquidationStatusOptions"
            :placeholder="$t('Select a liquidation status')"
            @select="selectOrganizationLiquidationStatus"
            @clear="clearOrganizationLiquidationStatus"
          />
          <form-thux-horizontal-select
            :validator="$v.row.sole_shareholder"
            :external-errors="errors['sole_shareholder']"
            :label-form="'Sole shareholder' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="row.sole_shareholder ? { value: row.sole_shareholder, name: row.get_sole_shareholder_display } : null"
            :options="organizationSoleShareholderOptions"
            :placeholder="$t('Select a shareholder')"
            @select="selectOrganizationSoleShareholder"
            @clear="clearOrganizationSoleShareholder"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          v-if="formEditable"
          class="mr-3"
          variant="default"
          @click.prevent="cancel()"
        >
          {{ 'Cancel' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.row.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { helpers } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ROW_TYPES } from './store'
import moment from 'moment'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import AtecoCodeMixin from './mixins/AtecoCodeMixin'
import CclnMixin from './mixins/CclnMixin'
import EqualOrganizationMixin from './mixins/EqualOrganizationMixin'
import ProfessionalRegisterMixin from './mixins/ProfessionalRegisterMixin'
import OrganizationTaxRegimeMixin from './mixins/OrganizationTaxRegimeMixin'
import LiquidationStatusMixin from './mixins/LiquidationStatusMixin'
import SoleShareholderMixin from './mixins/SoleShareholderMixin'

const REA_CODE_REGEX = helpers.regex('reaCodeRegex', /^[A-Z]{2}-[0-9]+$/)

export default {
  name: 'OrganizationRowEdit',
  mixins: [
    ThuxDetailPanelMixin,
    AtecoCodeMixin,
    CclnMixin,
    EqualOrganizationMixin,
    ProfessionalRegisterMixin,
    OrganizationTaxRegimeMixin,
    LiquidationStatusMixin,
    SoleShareholderMixin
  ],
  props: ['orgId', 'orgType'],
  data () {
    return {
      row: { ateco_codes: [] },
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        locale: 'it'
      },
      rowId: null,
      dateStartActivity: undefined,
      rolePerm: 'organization_organizationrow_retrieve',
      editRolePerm: 'organization_organizationrow_update'
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_ROW_TYPES.GENERIC.organization.organizationrow.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...ORG_ROW_TYPES.GENERIC.organization.organizationrow.DETAIL.ACTIONS
    }),
    ...mapMutations({
      setRowDetail: ORG_ROW_TYPES.GENERIC.organization.organizationrow.DETAIL.MUTATIONS.setDetail,
      ...ORG_ROW_TYPES.GENERIC.organization.organizationrow.LIST.MUTATIONS
    }),
    dateStartChange (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.row, 'date_start_activity', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.row, 'date_start_activity', undefined)
      }
    },
    cancel () {
      const orgRow = Object.assign({}, this.detail)
      if (orgRow.date_start_activity) {
        const dateStart = moment(orgRow.date_start_activity)
        this.$set(this, 'dateStartActivity', dateStart)
      }
      if (orgRow.ateco_codes) {
        const filters = {}
        filters.id__in = orgRow.ateco_codes
        filters.order_by = '-status.-date_create'
        this.getAtecolist(filters).then(() => {
          this.$set(orgRow, 'ateco_codes', this.getAtecoCodes(orgRow))
          this.$set(this, 'row', orgRow)
        })
      } else {
        this.$set(this, 'row', orgRow)
      }

      this.formEditable = false
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        const formData = Object.assign({}, this.row)
        if (!formData.organization) {
          formData.organization = this.orgId
        }
        this.setAtecoCodes(formData)
        const promise = this.rowId ? this.update(formData) : this.create(formData)
        promise.then(() => {
          this.$set(this, 'rowId', this.detail.id)
          const orgRow = Object.assign({}, this.detail)
          if (orgRow.date_start_activity) {
            const dateStart = moment(orgRow.date_start_activity)
            this.$set(this, 'dateStartActivity', dateStart)
          }
          if (orgRow.ateco_codes && orgRow.ateco_codes.length > 0) {
            const filters = {}
            filters.filter__id__in = [orgRow.ateco_codes]
            filters.order_by = '-status.-date_create'
            this.getAtecolist(filters).then(() => {
              this.$set(orgRow, 'ateco_codes', this.getAtecoCodes(orgRow))
              this.$set(this, 'row', orgRow)
            })
          } else {
            this.$set(this, 'row', orgRow)
          }
          this.formEditable = false
        })
      }
    },
    initFields () {
      this.$set(this, 'rowId', this.id)
    },
    setForm () {
      if (this.id) {
        const orgRow = Object.assign({}, this.detail)
        if (orgRow.date_start_activity) {
          const dateStart = moment(orgRow.date_start_activity)
          this.$set(this, 'dateStartActivity', dateStart)
        }
        if (orgRow.ateco_codes && orgRow.ateco_codes.length > 0) {
          const filters = {}
          filters.filter__id__in = [orgRow.ateco_codes]
          filters.order_by = '-status.-date_create'
          this.getAtecolist(filters).then(() => {
            this.$set(orgRow, 'ateco_codes', this.getAtecoCodes(orgRow))
            this.$set(this, 'row', orgRow)
          })
        } else {
          this.$set(this, 'row', orgRow)
        }
      } else {
        this.setRowDetail({ ateco_codes: [] })
      }
    }
  },
  validations () {
    const vals = {
      row: {
        date_start_activity: { },
        inail_code: { },
        inps_code: { },
        rea_code: { REA_CODE_REGEX },
        activity_desc: { },
        equal_organization: { },
        ccnl: { },
        ateco_codes: { },
        rct_insurance: { },
        rco_insurance: { },
        craftsmen_register_code: { },
        construction_fund: { },
        waste_manager_register: { },
        tax_regime: {},
        share_capital: {},
        liquidation_status: {},
        sole_shareholder: {}
      }
    }
    return vals
  }
}
</script>
