<script>

import { vue } from '../../../../../main'

export default {
  name: 'OrganizationGroupMixin',
  props: {
    orgGroupList: { required: true }
  },
  computed: {
    organizationGroupListOptions () {
      if (this.orgGroupList) {
        return this.prepareListForMultiSelect(this.orgGroupList.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    onSearchGroup (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.$emit('search-group', filters)
      } else {
        this.$emit('search-group')
        vue.$store.commit('componentLoading', true)
      }
    },
    setOrganizationGroup (formData) {
      if (this.form.organization_groups) {
        const list = []
        this.form.organization_groups.forEach((row) => list.push(row.id))
        this.$set(formData, 'organization_groups', list)
      } else {
        this.$set(formData, 'organization_groups', [])
      }
      return formData
    },
    selectOrganizationGroup (item) {
      if (!this.form.organization_groups) {
        this.$set(this.form, 'organization_groups', [])
      }
      this.form.organization_groups.push(item)
    },
    removeOrganizationGroup (item) {
      const organizationGroup = this.form.organization_groups.filter((addressScope) => {
        return addressScope.id !== item.id
      })
      this.$set(this.form, 'organization_groups', organizationGroup)
      if (this.form.organization_groups.length === 0) this.$set(this.form, 'organization_groups', null)
    }
  }
}
</script>
